import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { theme } from '../../../../theme';
import { CompanyLogoAndName } from '../../../grid-renderers/CompanyCellRenderer';

import { cardStyles } from '../../../../theme/component-styles';
import { MostRecentChartData } from '../../../../pages/PortfolioOverview/hooks/useMostRecentRounds';
import { FMT } from '../../../../util/formatter-service';

const { critical, primary, neutral, warning, secondary } = theme.colors;

const RoundWrapper = styled(Typography)<{ backgroundColor: string }>`
  align-items: center;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

interface IRoundMeta {
  backgroundColor: string;
  color: string;
  label: string;
}

const RoundMeta: Record<string, IRoundMeta> = {
  'Series A': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'A',
  },
  'Series B': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'B',
  },
  'Series C': {
    backgroundColor: primary[10],
    color: primary[60],
    label: 'C',
  },
  'Series D': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'D',
  },
  'Series E': {
    backgroundColor: critical[10],
    color: critical[50],
    label: 'E',
  },
  'Series F': {
    backgroundColor: warning[10],
    color: warning[60],
    label: 'F',
  },
  'Series G': {
    backgroundColor: secondary[10],
    color: secondary[60],
    label: 'G',
  },
};

function getRoundMeta(roundId: keyof typeof RoundMeta) {
  return (
    RoundMeta[roundId] ?? {
      backgroundColor: neutral[10],
      color: 'black',
      label: roundId,
    }
  );
}

export const PortfolioChartRow = styled('li')`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
  @supports not (grid-template-columns: subgrid) {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }
`;

export function MostRecentRow({
  company: { name, logoUrl },
  round,
  date: mostRecent,
  amount: postMoneyValuation,
}: MostRecentChartData): ReactElement {
  const {
    backgroundColor = '',
    color = '',
    label = '',
  } = round ? getRoundMeta(round as keyof typeof RoundMeta) : {};

  return (
    <PortfolioChartRow style={{ ...cardStyles, padding: '0.25rem 0.5rem', minWidth: '1rem' }}>
      <CompanyLogoAndName name={name} logoUrl={logoUrl ?? ''} />
      {round ? (
        <RoundWrapper backgroundColor={backgroundColor} variant='caption' color={color} textAlign={'center'}>
          {label}
        </RoundWrapper>
      ) : null}
      <Typography variant={'body2'} noWrap sx={{ display: 'grid', placeItems: 'center' }}>
        {FMT.format('monthYear', mostRecent)}
      </Typography>
      <Typography
        variant={'body2'}
        noWrap
        sx={{ display: 'grid', alignItems: 'center', justifyContent: 'end' }}
      >
        {FMT.format('usdShort', postMoneyValuation)}
      </Typography>
    </PortfolioChartRow>
  );
}

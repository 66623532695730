import {
  TransactionAudit,
  TransactionPayload,
  TransactionResponse,
  TransactionTWithCompanies,
} from '../../types';
import { MaggieHttp } from '../MaggieHttp';
import { ITransactionDataModel } from '../../data-models/transaction.data-model';
import { IFundDataModel } from '../../data-models/fund.data-model';
import { IRoundDataModel } from '../../data-models/round.data-model';
import { TransactionType } from '../../data-models/transaction-type.data-model';
import { IFinanceRoundDataModel } from '../../data-models/finance-round.data-model';
import {
  IRoundReconConfigDataModel,
  IRoundReconDataModel,
} from '../../data-models/finance-round-recon.data-model';
import { IFinanceDashboardResponseDataModel } from '../../data-models/dashboard-transaction.data-model';
import { ICompanyMetricsDataModel } from '../../data-models/metrics.data-model';
import { ReconConfigDataModel } from '../../schemas/ReconConfig.schema';
import { MetricsReconDataModel } from '../../schemas/MetricsRecon.schema';
import { GeneralLedgerUploadData } from '../../schemas/GeneralLedgerUploadData.schema';

export interface IUpdateTransactionPayload extends Partial<Omit<ITransactionDataModel, 'round'>> {
  companyId: number;
  id: number;
  round?: Partial<IFinanceRoundDataModel> | null;
}

export type UpdateTransactionPayload = {
  payload: IUpdateTransactionPayload;
  transactionId: number;
};

export async function fetchTransactionsByCompany2(companyId: number) {
  const response = await fetchTransactionsByCompany(companyId);
  return response.transactions;
}

export async function fetchTransactionsByCompany(
  companyId?: number | null
): Promise<TransactionTWithCompanies> {
  const response = await MaggieHttp.maggie.get<TransactionResponse>(`/transaction`, {
    params: {
      companies: [companyId],
    },
  });
  const transactions = response.data?.transactions;
  const roundMap: { [k: string]: IRoundDataModel } = {};
  const fundMap: { [k: string]: IFundDataModel } = {};
  const transTypeMap: { [k: string]: TransactionType } = {};
  if (response.data && response.data.rounds) {
    for (const round of response.data.rounds) {
      roundMap[round.id] = round;
    }
  }
  if (response.data && response.data.funds) {
    for (const fund of response.data.funds) {
      fundMap[fund.id] = fund;
    }
  }
  if (response.data && response.data.transactionTypes) {
    for (const transType of response.data.transactionTypes) {
      transTypeMap[transType.id] = transType;
    }
  }

  transactions?.forEach((transaction) => {
    if (transaction.fundId) transaction.fund = fundMap[transaction.fundId];
    if (transaction.roundId && !transaction.roundNormalized) {
      transaction.roundNormalized = roundMap[transaction.roundId];
    }
  });

  return { transactions: transactions || [], companies: response.data.companies };
}

export async function createTransaction(transactionPayload: TransactionPayload) {
  const { data } = await MaggieHttp.maggie.post<TransactionPayload, ITransactionDataModel>(
    '/transaction',
    transactionPayload
  );
  return data;
}

export async function fetchTransactionHistory(companyId?: number) {
  const response = await MaggieHttp.maggie.get<TransactionAudit[]>(`/transaction/${companyId}/history`);

  return response.data;
}

export async function fetchTransactions2(params?: {
  fromDate: string;
  toDate: string;
}): Promise<TransactionResponse> {
  const { data } = await MaggieHttp.maggie.get<TransactionResponse>('/transaction', {
    params,
  });
  return data;
}

export async function fetchTransactions(): Promise<TransactionTWithCompanies> {
  const response = await MaggieHttp.maggie.get<TransactionResponse>('/transaction');

  const transactions = response.data?.transactions;
  const roundMap: { [k: string]: IRoundDataModel } = {};
  const fundMap: { [k: string]: IFundDataModel } = {};
  if (response.data && response.data.rounds) {
    for (const round of response.data.rounds) {
      roundMap[round.id] = round;
    }
  }
  if (response.data && response.data.funds) {
    for (const fund of response.data.funds) {
      fundMap[fund.id] = fund;
    }
  }

  transactions?.forEach((transaction) => {
    if (transaction.fundId) transaction.fund = fundMap[transaction.fundId];
    if (transaction.roundId && !transaction.roundNormalized) {
      transaction.roundNormalized = roundMap[transaction.roundId];
    }
  });

  return { transactions, companies: response.data.companies };
}

export async function updateTransaction({
  payload,
  transactionId,
}: UpdateTransactionPayload): Promise<ITransactionDataModel> {
  const { data } = await MaggieHttp.maggie.put<IUpdateTransactionPayload, ITransactionDataModel>(
    `/transaction/${transactionId}`,
    payload
  );

  return data;
}

export async function deleteTransaction(id: number) {
  await MaggieHttp.maggie.delete<number>(`/transaction/${id}`);

  return id;
}

export async function fetchMetricsRecon(): Promise<MetricsReconDataModel[]> {
  const response = await MaggieHttp.maggie.get<MetricsReconDataModel[]>('/recon-value');

  return response.data;
}

export async function fetchReconConfig(): Promise<ReconConfigDataModel[]> {
  const { data } = await MaggieHttp.maggie.get<ReconConfigDataModel[]>('/recon-config');
  return data;
}

export async function fetchRoundsForCompany(companyId: number): Promise<IFinanceRoundDataModel[]> {
  const response = await MaggieHttp.maggie.get<IFinanceRoundDataModel[]>(`/round`, {
    params: {
      companyId,
    },
  });

  return response.data;
}

export async function fetchAllInvestmentRounds(): Promise<IFinanceRoundDataModel[]> {
  const response = await MaggieHttp.maggie.get<IFinanceRoundDataModel[]>(`/round`);
  return response.data;
}

export async function updateInvestment(
  investment: Partial<IFinanceRoundDataModel>
): Promise<IFinanceRoundDataModel> {
  const response = await MaggieHttp.maggie.put<Partial<IFinanceRoundDataModel>, IFinanceRoundDataModel>(
    `/round/${investment.id}`,
    investment
  );

  return response.data;
}

export async function createInvestment(
  investment: Partial<IFinanceRoundDataModel>
): Promise<IFinanceRoundDataModel> {
  const { data } = await MaggieHttp.maggie.post<Partial<IFinanceRoundDataModel>, IFinanceRoundDataModel>(
    `/round`,
    {
      ...investment,
    }
  );
  return data;
}

export async function deleteInvestment(investmentId: number, force = false) {
  await MaggieHttp.maggie.delete<number>(`/round/${investmentId}`, {
    params: {
      force: force ? true : undefined, // api interprets any value as true, so we need undefined for false
    },
  });
}

export async function fetchRoundRecon(): Promise<IRoundReconDataModel[]> {
  const response = await MaggieHttp.maggie.get<IRoundReconDataModel[]>('/roundRecon');
  return response.data;
}

export async function fetchRoundReconConfig(): Promise<IRoundReconConfigDataModel> {
  const { data } = await MaggieHttp.maggie.get<IRoundReconConfigDataModel>('roundRecon/config');
  return data;
}

export async function fetchDashboardData(): Promise<IFinanceDashboardResponseDataModel> {
  const response = await MaggieHttp.maggie.get<IFinanceDashboardResponseDataModel>('/transaction/dashboard');
  return response.data;
}

export async function getTransactionPreview(
  transaction: TransactionPayload
): Promise<ICompanyMetricsDataModel> {
  const { data } = await MaggieHttp.maggie.post<TransactionPayload, ICompanyMetricsDataModel>(
    '/transaction/preview',
    transaction
  );
  return data;
}

export async function uploadGeneralLedger(formData: FormData, date: string) {
  const { data } = await MaggieHttp.maggie.post<FormData, IFinanceRoundDataModel>(
    '/file-upload/carta/general-ledger',
    formData,
    {
      params: {
        date,
      },
    }
  );
  return data;
}

export async function getGeneralLedgerUploadData() {
  const { data } = await MaggieHttp.maggie.get<GeneralLedgerUploadData[]>(
    '/file-upload/carta/general-ledger/status'
  );
  return data;
}

import { TextField } from '@mui/material';
import { IFormFieldTextMeta } from '../../../view-models/form.view-model';
import { formTextFieldInlineClassNames } from '../../../theme/component-styles';
import { IBaseFieldProps } from './types';

export function FieldText(props: IBaseFieldProps<IFormFieldTextMeta>) {
  const { formField, formProps } = props;
  const { onChange, onBlur } = formProps;
  const { maxRows, multiline, rows, placeholder } = formField.rendererMeta ?? {};
  const className = formField?.variant === 'form-inline' ? formTextFieldInlineClassNames : '';

  return (
    <TextField
      className={className}
      disabled={formField?.disabled}
      inputProps={{
        defaultValue: formProps.value,
        onBlur,
        onChange,
      }}
      rows={rows}
      maxRows={maxRows}
      multiline={multiline}
      placeholder={placeholder}
    />
  );
}

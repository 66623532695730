import * as yup from 'yup';
import { IAggFunc, IAggFuncParams, RowClassParams } from 'ag-grid-community';
import { FundReserves, InvestmentType, investmentTypeToLabel } from '../../../schemas/FundReserves.schema';
import { usdField } from '../../../schemas/common-schema-defs';
import { HideGroupAgg } from '../../../components/AgTable/cell-renderers/HideGroupAgg';
import { RendererType } from '../../../data-models/field.data-model';
import { ReservesRenderer } from './reservesGridColDefs';

/*
- reserves used is prob. reserves invested and reserves assigned combined into one
- All investment amounts except the initial one will count towards reserves used (current ones will count towards reserves invested, future ones towards reserves assigned)
*/

export const reservesAggFuncs: Record<string, IAggFunc<ReservesRowData>> = {
  // remaining & allocated reserves should only be aggregated at grand total / fund level
  // (we don't have reserves allocation data at company level)
  remainingReservesAggFunc: (params: IAggFuncParams<ReservesRowData>) => {
    // there is also a field reserves.summary.reservesAllocationRemaining, but we might
    // want to do it dynamically as hypothetical transactions are added
    const aggData = params.rowNode?.aggData;
    // return (aggData?.reservesAllocated ?? 0) - (aggData?.reservesUsed ?? 0);
    // or if we should separate reservesUsed into reservesInvested and reservesAssigned:
    return (
      (aggData?.reservesAllocated ?? 0) - (aggData?.reservesInvested ?? 0) - (aggData?.reservesAssigned ?? 0)
    );
  },
  reservesAllocatedAggFunc: (params: IAggFuncParams<ReservesRowData>) => {
    return params.context.fund.reservesAllocationAmount;
  },
};

export function ReservesRowDataFields() {
  return {
    companyId: yup.number().nullable().label('Company').gridMeta({
      renderer: RendererType.companyId,
    }),
    companyName: yup.string().nullable().label('Company'),
    date: yup.date(),
    hypothetical: yup.boolean().nullable().gridMeta({
      hide: true,
    }),
    initialInvestment: usdField().nullable().gridMeta({
      aggFunc: 'sum',
    }),
    investmentAmount: usdField().nullable().gridMeta({
      aggFunc: 'sum',
    }),
    reservesAllocated: usdField().nullable().gridMeta({
      aggFunc: 'reservesAllocatedAggFunc', // fake agg, only at fund level
      cellRenderer: HideGroupAgg,
    }),
    reservesUsed: usdField().nullable().gridMeta({
      // reserves assigned???
      aggFunc: 'sum',
    }),
    reservesRemaining: usdField().nullable().gridMeta({
      aggFunc: 'remainingReservesAggFunc',
      cellRenderer: ReservesRenderer,
    }),

    // if reservesUsed is merged reservesInvested and reservesAssigned
    // this would be the two cols separated:
    reservesInvested: usdField().nullable().gridMeta({
      aggFunc: 'sum',
    }),
    reservesAssigned: usdField().nullable().gridMeta({
      aggFunc: 'sum',
    }),

    type: yup.string().nullable().label('Investment'),
  };
}
export function ReservesRowDataSchema() {
  return yup.object().shape(ReservesRowDataFields());
}
export type ReservesRowData = yup.InferType<ReturnType<typeof ReservesRowDataSchema>>;

export function getReservesData(reserves: FundReserves) {
  const portcoData: ReservesRowData[] = [];
  const hypotheticalData: ReservesRowData[] = [];

  (reserves.investments ?? []).forEach((investment) => {
    const { companyId, name: companyName } = investment;
    investment.transactions.forEach((transaction) => {
      const { hypothetical } = transaction;
      const result = {
        companyId: companyId ?? null,
        companyName: companyId ? null : companyName,
        date: transaction.date as Date,
        hypothetical,
        investmentAmount: transaction.amount,
        initialInvestment: transaction.type === InvestmentType.Initial ? transaction.amount : null,
        reservesAssigned: hypothetical ? transaction.amount : null, // sumByProperty('amount', futureInvestment.transactions)
        reservesInvested:
          !hypothetical && transaction.type === InvestmentType.FollowOn ? transaction.amount : null, // fundMetrics.metrics.followOnInvestmentAmount
        reservesUsed:
          (!hypothetical && transaction.type === InvestmentType.FollowOn) || hypothetical
            ? transaction.amount
            : null,
        type: investmentTypeToLabel[transaction.type as InvestmentType] ?? null,
      };
      if (companyId == null) {
        hypotheticalData.push(result);
      } else {
        portcoData.push(result);
      }
    });
  });
  return { portcoData, hypotheticalData };
}

export const reservesRowClassRules = {
  currentInvestment: (params: RowClassParams) => {
    return params.data?.hypothetical === false;
  },
};

import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { FieldPath } from 'react-hook-form';
import { useAllCompanyDetailsFields } from '../../CompanyProfiles2/Summary/useCompanyDetailsFields';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useAllInvestmentMetricsFields } from '../../CompanyProfiles2/Summary/useInvestmentMetricsFields';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { PreferencesServiceV3 } from '../../../util/preferences-service-v3';
import {
  PanelPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../data-models/preferences-v3.data-model';
import { selectedDetailsFieldsOrgState, selectedMetricsFieldsOrgState } from './DataDisplayState';

export function useDetailsConfigActions() {
  const allFields = useAllCompanyDetailsFields();
  const [selectedFields, setSelectedFields] = useRecoilState(selectedDetailsFieldsOrgState);

  const handleReset = useCallback(async () => {
    const allFieldsKeys = allFields.map((field) => field.key as FieldPath<ICompanyDataModel>);
    setSelectedFields(allFieldsKeys);

    PreferencesServiceV3.get().setPreference<PanelPreferences>(
      PreferenceKey.companySummaryDetails,
      {
        selectedFields: allFieldsKeys,
      },
      PreferenceScope.ORGANIZATION
    );
  }, [allFields, setSelectedFields]);

  const handleSave = useCallback(async () => {
    PreferencesServiceV3.get().setPreference<PanelPreferences>(
      PreferenceKey.companySummaryDetails,
      {
        selectedFields: selectedFields ?? [],
      },
      PreferenceScope.ORGANIZATION
    );
  }, [selectedFields]);

  return { handleReset, handleSave };
}

export function useMetricsConfigActions() {
  const allFields = useAllInvestmentMetricsFields();
  const [selectedFields, setSelectedFields] = useRecoilState(selectedMetricsFieldsOrgState);

  const handleReset = useCallback(async () => {
    const allFieldsKeys = allFields.map((field) => field.key as FieldPath<CompanyFundMetricsDataModel>);
    setSelectedFields(allFieldsKeys);

    PreferencesServiceV3.get().setPreference<PanelPreferences>(
      PreferenceKey.companySummaryInvestments,
      {
        selectedFields: allFieldsKeys,
      },
      PreferenceScope.ORGANIZATION
    );
  }, [allFields, setSelectedFields]);

  /** stub */
  const handleSave = useCallback(async () => {
    setSelectedFields(selectedFields);

    PreferencesServiceV3.get().setPreference<PanelPreferences>(
      PreferenceKey.companySummaryInvestments,
      {
        selectedFields: selectedFields ?? [],
      },
      PreferenceScope.ORGANIZATION
    );
  }, [selectedFields, setSelectedFields]);

  return { handleReset, handleSave };
}

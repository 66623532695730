import { useRecoilValue, useSetRecoilState } from 'recoil';
import { css } from '@emotion/react';
import { Chip, Divider, IconButton, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { capitalize } from 'lodash-es';
import { Link } from 'react-router-dom';
import { KPIRequestResponse } from '../../../../KPI/components/KPIRequests/KPIRequestResponse/KPIRequestResponse';
import { isDrawerOpenState, useResetDrawerResponsesState } from '../../../state/UIState';
import { ActivityHeader } from '../ActivityHeader';
import {
  kpisRequestByIdState,
  kpisRequestResponseByIdState2,
} from '../../../../../services/state/KPI/KPIRequestsState';
import { FMT } from '../../../../../util/formatter-service';
import {
  IKPIRequestDataModel,
  IKPIRequestResponse,
  KPIRequestStatus,
} from '../../../../../data-models/kpi-requests.data-model';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { colors } from '../../../../../theme/colors';
import { RequestStatus } from './RequestList';

const ViewerContainer = css`
  display: grid;
  grid-template-rows: min-content min-content auto 1fr;
`;

const ViewerHeader = css`
  display: grid;
  grid-template-columns: max-content auto auto 1fr;
  align-items: center;
  column-gap: 0.5rem;
`;

const DetailsContainer = css`
  display: grid;
  padding: 1rem;
  grid-template-columns: max-content 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
  background: ${colors.neutral[5]};
  margin: 1rem 0.5rem;
`;

const DetailsRow = css`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
`;

export function ResponseViewer({ requestId }: { requestId: number }) {
  const request = useRecoilValue(kpisRequestByIdState(requestId));
  const response = useRecoilValue(kpisRequestResponseByIdState2(requestId));
  const setIsOpenDrawer = useSetRecoilState(isDrawerOpenState);
  const resetDrawerState = useResetDrawerResponsesState();
  const onBackClick = () => resetDrawerState();
  const onCloseClick = () => {
    resetDrawerState();
    setIsOpenDrawer(false);
  };

  if (!request || !response) return null;
  return (
    <ResponseViewerContent
      onBackClick={onBackClick}
      onCloseClick={onCloseClick}
      request={request}
      response={response}
    />
  );
}

interface IResponseViewerContentProps {
  request: IKPIRequestDataModel;
  response: IKPIRequestResponse;
  onBackClick: () => void;
  onCloseClick: () => void;
}
export function ResponseViewerContent({
  request,
  response,
  onBackClick,
  onCloseClick,
}: IResponseViewerContentProps) {
  return (
    <div css={ViewerContainer}>
      <ActivityHeader
        onBackClick={onBackClick}
        onCloseClick={onCloseClick}
        title={
          <Typography component={'div'}>
            <ResponseHeaderContent request={request} />
          </Typography>
        }
      />
      <Divider />
      <ResponseDetails request={request} response={response} />
      <div>
        <ResponseLink request={request} />
        <KPIRequestResponse
          reqId={request.id}
          readOnly
          showHeader={false}
          style={{ maxWidth: '450px', padding: '0.5rem' }}
        />
      </div>
    </div>
  );
}
function ResponseHeaderContent({ request }: { request: IKPIRequestDataModel }) {
  return (
    <div css={ViewerHeader}>
      <Typography variant='h6'>
        {FMT.formatKPIPeriod(request.period, {
          frequency: request.frequency,
          showYear: true,
          yearFormat: 'yyyy',
        })}
      </Typography>
      <Chip label={capitalize(request.frequency)} />
      <RequestStatus status={request.status as KPIRequestStatus} />
    </div>
  );
}

interface IResponseDetailsProps {
  request: IKPIRequestDataModel;
  response: IKPIRequestResponse;
}
function ResponseDetails({ request, response }: IResponseDetailsProps) {
  return (
    <div css={DetailsContainer}>
      <div css={DetailsRow}>
        <Typography variant='body2'>Respondents</Typography>
        <div>
          {request.respondent?.map((respondent) => (
            <Typography key={respondent!} component={'div'} variant='body2' display={'flex'} gap={'0.2rem'}>
              <MUIAvatar
                key={respondent}
                src={''}
                nameParts={FMT.format('userByEmail', respondent ?? '')?.split(' ') ?? []}
              />
              {FMT.format('userByEmail', respondent)}
            </Typography>
          ))}
        </div>
      </div>
      <div css={DetailsRow}>
        <Typography variant='body2'>Receiving Date</Typography>
        <Typography variant='body2'>{FMT.format('date', response.respondedAt)}</Typography>
      </div>
    </div>
  );
}

function ResponseLink({ request }: { request: IKPIRequestDataModel }) {
  return (
    <div style={{ height: '0' }}>
      <IconButton
        component={Link}
        to={`/${ROUTES.KPI_REQUEST_REQUESTID_RESPONSE}`.replace(':requestId', `${request.id}`)}
        target='blank'
        sx={{
          display: 'flex',
          width: 'fit-content',
          alignItems: 'center',
          marginLeft: 'auto',
          transform: 'translate(-1rem, 1rem)',
          boxShadow: '0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
      >
        <OpenInNewIcon fontSize='small' color='secondary' />
      </IconButton>
    </div>
  );
}

import { MostRecentDataChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentDataChart';
import { CHART_COMPONENTS } from '../../../../types';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { useMostRecentParticipatingRounds } from '../../hooks/useMostRecentParticipatingRounds';
import { IPortfolioChartProps } from './ChartComponent';

export function MostRecentParticipatingRounds(props: IPortfolioChartProps<IMetricsDataModel>) {
  const data = useMostRecentParticipatingRounds(props.filteredMetrics);

  return (
    <MostRecentDataChart
      id={CHART_COMPONENTS.MOST_RECENT_PARTICIPATING_ROUNDS}
      width={640}
      data={data}
      columnHeaders={['Company', 'Date', 'Investment Amount']}
    />
  );
}

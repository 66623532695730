import { cloneDeep } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { createITransactionDataModel, ITransactionDataModel } from '../data-models/transaction.data-model';
import { transactionTypesMapState } from '../services/state/AppConfigState';
import { TRANSACTION_SUB_TYPES } from '../types';
import { TransactionTypeService } from '../util/transaction-type-service';

export enum WarrantsType {
  numberOfWarrants = 'Number of Warrants',
  totalValueOfWarrants = 'Total Value of Warrants',
}

export interface ITransactionViewModel extends ITransactionDataModel {
  _viewModel: {
    ppsShareClass?: Record<string, number> | null;
    exchangedSharesByRestructureId?: Record<string, number> | null;
    showPpsShareClass?: boolean;
    transactionCategory?: string;
    warrantsType?: WarrantsType;
  };
}

export function createTransactionViewModel(
  overrides: Partial<ITransactionViewModel> = {}
): ITransactionViewModel {
  return {
    _viewModel: {
      ppsShareClass: {},
      exchangedSharesByRestructureId: {},
      showPpsShareClass: false,
      warrantsType: undefined,
    },
    ...createITransactionDataModel({
      ...overrides,
    }),
  };
}

export function useFromTransactionDataModel() {
  const transactionTypeMap = useRecoilValue(transactionTypesMapState);

  return useCallback(
    async (transaction: Partial<ITransactionDataModel>): Promise<Partial<ITransactionViewModel>> => {
      const subtypeId = transaction.transactionTypeId;

      let warrantsType: WarrantsType | undefined;

      if (transactionTypeMap.get(subtypeId ?? -1)?.name === TRANSACTION_SUB_TYPES.WARRANT_ISSUANCE) {
        if (transaction.aggregatedValue) {
          warrantsType = WarrantsType.totalValueOfWarrants;
        } else {
          warrantsType = WarrantsType.numberOfWarrants;
        }
      }

      return createTransactionViewModel({
        _viewModel: {
          showPpsShareClass:
            typeof transaction.ppsShareClass === 'object' && transaction.valuationImpact === false,
          ppsShareClass: transaction.ppsShareClass
            ? getPpsPerShareClassViewModel(transaction.ppsShareClass)
            : {},
          exchangedSharesByRestructureId: transaction.exchangedSharesByRestructureId
            ? getPpsPerShareClassViewModel(transaction.exchangedSharesByRestructureId)
            : {},
          transactionCategory: transactionTypeMap.get(subtypeId ?? -1)?.catergory ?? undefined,
          warrantsType,
        },
        ...cloneDeep(transaction),
      });
    },
    [transactionTypeMap]
  );
}

export function toTransactionDataModel(
  transactionViewModel: Partial<ITransactionViewModel>
): Partial<ITransactionDataModel> {
  const { _viewModel, ...dataModel } = transactionViewModel;
  const transaction = cloneDeep(dataModel);
  const transTypeSvc = TransactionTypeService.get();

  if (
    _viewModel &&
    transTypeSvc.getTransactionType(dataModel.transactionTypeId ?? -1) ===
      TRANSACTION_SUB_TYPES.INTERNAL_VALUATION
  ) {
    if (_viewModel.showPpsShareClass && _viewModel.ppsShareClass) {
      transaction.valuationImpact = false;
      transaction.ppsShareClass = getPpsPerShareClassDataModel(_viewModel.ppsShareClass);
    } else {
      transaction.valuationImpact = true;
    }
  }

  if (
    _viewModel &&
    transTypeSvc.getTransactionType(dataModel.transactionTypeId ?? -1) ===
      TRANSACTION_SUB_TYPES.SHARE_EXCHANGE
  ) {
    if (_viewModel.exchangedSharesByRestructureId) {
      transaction.exchangedSharesByRestructureId = getPpsPerShareClassDataModel(
        _viewModel.exchangedSharesByRestructureId
      );
    }
  }

  if (_viewModel?.warrantsType === WarrantsType.numberOfWarrants) {
    transaction.aggregatedValue = undefined;
  } else if (_viewModel?.warrantsType === WarrantsType.totalValueOfWarrants) {
    transaction.warrantsIssued = undefined;
    transaction.warrantAcquiredPrice = undefined;
  }

  return transaction;
}

export function getPpsPerShareClassViewModel(ppsShareClass: Record<string, number>) {
  const res: Record<string, number> = {};
  const restructuringIds = Object.keys(ppsShareClass ?? {});

  restructuringIds.forEach((restructuringId) => {
    res[restructureKeyToFieldKey(restructuringId)] = ppsShareClass[restructuringId];
  });

  return res;
}

function getPpsPerShareClassDataModel(ppsShareClass: Record<string, number>) {
  const res: Record<string, number> = {};
  const restructuringIds = Object.keys(ppsShareClass ?? {});

  restructuringIds.forEach((restructuringId) => {
    res[fieldKeyToRestructureKey(restructuringId)] = ppsShareClass[restructuringId];
  });

  return res;
}

export function restructureKeyToFieldKey(restructureId: string) {
  return `r${restructureId.replaceAll('.', '_')}`;
}

export function fieldKeyToRestructureKey(fieldKey: string) {
  return fieldKey.replaceAll('_', '.').substring(1);
}

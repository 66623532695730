import {
  CellClassParams,
  ColDef,
  ColDefField,
  ISetFilterParams,
  KeyCreatorParams,
  NestedFieldPaths,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { get } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AnyObject, ISchema, Maybe, ObjectSchema, SchemaDescription } from 'yup';
import { BooleanRenderer, BooleanRenderer2 } from '../components/AgTable/cell-renderers/BooleanRenderer';
import { GenericIdRenderer } from '../components/AgTable/cell-renderers/GenericIdRenderer';
import {
  RoundCellRenderer,
  RoundFromInvestmentCellRenderer,
} from '../components/AgTable/cell-renderers/RoundCellRenderer';
import { StringListCellRenderer } from '../components/AgTable/cell-renderers/StringListCellRenderer';
import {
  formatterIdToExcelStyleId,
  getExcelClassForCurrency,
  rendererTypeToExcelStyleId,
} from '../components/AgTable/exportToExcelDefs';
import { CompanyIdCellRenderer } from '../components/grid-renderers/CompanyCellRenderer';
import { RendererType } from '../data-models/field.data-model';
import { PrimitiveType } from '../data-models/field3.data-model';
import { IFormatterDataModel } from '../data-models/formatter.data-model';
import { useGetCompanyIfSet } from '../pages/CompanyProfiles/hooks/useGetCompanyData';
import {
  getDateColDefs,
  getNumericColdDef,
  numericCellClasses,
} from '../pages/Finance2/common-grid-defs/commonColDefs';
import { currencyMapByIdState } from '../services/state/AppConfigState';
import { kpiTemplatesMapByUuidState } from '../services/state/KPI/KPITemplatesState';
import { IDisplayField } from '../view-models/display-field.view-model';
import { createFormField, IFormField } from '../view-models/form.view-model';
import { IGridField } from '../view-models/grid.view-model';
import { AgFormatterService } from './ag-formatter-service';
import { FormatterService, StandardFormatterId } from './formatter-service';
import { BooleanFormattersConfig } from './formatters/BooleanFormatters';
import { camelCaseToCapitalizedString } from './stringUtils';

const PrimitiveTypeToRenderer: Record<PrimitiveType, RendererType> = {
  array: RendererType.multiSelect,
  boolean: RendererType.boolean,
  date: RendererType.date,
  number: RendererType.number,
  string: RendererType.text,
};

export function useSchemaToGrid() {
  const schemaFieldToColDef = useSchemaToColDef();

  return useCallback(
    <T extends Maybe<AnyObject>>(schema: ObjectSchema<T>, fieldsToShow?: ColDefField<T>[]): ColDef<T>[] => {
      return (fieldsToShow ?? Object.keys(schema.fields)).map((fieldId) => {
        const field = get(schema.fields, getSchemaFieldsPath(fieldId));
        const desc = field.describe() as SchemaDescription;

        return schemaFieldToColDef<T>(fieldId as ColDefField<T>, desc);
      });
    },
    [schemaFieldToColDef]
  );
}

export function useSchemaToColDef() {
  const getExtendedColDefFromGridField = useGetExtendedColDefFromGridField();

  return useCallback(
    <T>(fieldId: ColDefField<T>, fieldDescription: SchemaDescription) => {
      const meta = fieldDescription.meta as Partial<IGridField<unknown>>;
      const type = fieldDescription.type as PrimitiveType;

      const field: IGridField<unknown> = {
        key: meta?.key ?? fieldId,
        renderer: PrimitiveTypeToRenderer[type],
        ...fieldDescription,
        ...meta,
      };

      return {
        ...getBaseColDefFromGridField<T>(field),
        ...getExtendedColDefFromGridField<T>(field),
      } as ColDef<T>;
    },
    [getExtendedColDefFromGridField]
  );
}

export function getBaseColDefFromGridField<T>(gridField: IGridField<unknown>): Partial<ColDef<T>> {
  const { key, label, dataType, aggFunc, editable, enableRowGroup, cellRenderer } = gridField;

  return {
    aggFunc,
    colId: key,
    editable,
    enableRowGroup,
    field: key as ColDefField<T>,
    headerName: label,
    headerTooltip: label,
    type: dataType,
    cellRenderer,
  } as Partial<ColDef<T>>;
}

function useGetExtendedColDefFromGridField() {
  const gridFormatters = useGridFormatters();
  const cellClasses = useGridCellClasses();

  return useCallback(
    <T>(gridField: IGridField<unknown>): ColDef<T> => {
      const agFormatter = AgFormatterService.get()?.getFormatterForFormField<T, unknown>(gridField);
      const formatter = FormatterService.get().getFormatterForFormField(gridField);
      const formatterId = gridField.formatter as StandardFormatterId;
      const style = formatterIdToExcelStyleId[formatterId] ?? rendererTypeToExcelStyleId[gridField.renderer];

      if (gridField.formatter === 'kpiTemplateName') {
        return {
          keyCreator: gridFormatters.templateNameFormatter,
          minWidth: 200,
          valueFormatter: gridFormatters.templateNameFormatter,
        } as ColDef<T>;
      }

      switch (gridField.renderer) {
        case RendererType.boolean: {
          return {
            cellRenderer:
              (gridField.formatter as IFormatterDataModel<unknown>)?.id ===
              BooleanFormattersConfig.trueFalse.id
                ? BooleanRenderer2
                : BooleanRenderer,
            filter: 'agSetColumnFilter',
            filterParams: {
              valueFormatter: agFormatter,
            } as ISetFilterParams,
            keyCreator: (params: KeyCreatorParams<T, boolean>) => {
              return formatter(params.value ?? false);
            },
            valueFormatter: agFormatter,
            valueGetter: (params: ValueGetterParams<T, boolean>) => {
              return get(params.data, gridField.key);
            },
          };
        }

        case RendererType.companyId:
          return {
            ...generateIdDataColDefs(gridFormatters.companyNameFromIdFormatter),
            keyCreator: gridFormatters.companyNameFromIdFormatter,
            minWidth: 200,
            valueFormatter: gridFormatters.companyNameFromIdFormatter,
            cellRenderer: CompanyIdCellRenderer,
          };

        case RendererType.currency:
          return {
            ...getNumericColdDef(),
            cellClass: cellClasses.currencyClass,
            valueFormatter: gridFormatters.currencyFormatter as ValueFormatterFunc<T, number>,
          };

        case RendererType.currencyId:
          return {
            cellClass: ['text-align-right'],
            valueFormatter: gridFormatters.currencyNameFromIdFormatter,
          };

        case RendererType.date:
          return {
            ...getDateColDefs(),
            valueFormatter: (params: ValueFormatterParams<T, Date>) => {
              if (!params.value) return '';
              return formatter(params.value);
            },
            cellClass: style ? [...numericCellClasses, style] : numericCellClasses,
          };

        case RendererType.id: {
          const formatterId = gridField.formatter as StandardFormatterId;
          const formatDataField = formatterId === 'transactionType';
          const commonDefs = generateIdDataColDefs<T>(
            AgFormatterService.get().getFormatterForId(formatterId),
            formatDataField
          );

          if (formatterId === 'round') {
            return {
              ...commonDefs,
              cellRenderer: RoundCellRenderer,
            };
          }

          if (formatterId === 'stage') {
            return {
              ...commonDefs,
              cellRenderer: RoundFromInvestmentCellRenderer,
              valueFormatter: (params: ValueFormatterParams) => {
                if (!params.value) return '';
                return formatter(params.value ?? -1);
              },
            };
          }

          return { ...commonDefs };
        }

        case RendererType.number:
        case RendererType.integer:
        case RendererType.multiplier:
        case RendererType.percent: {
          const cellClass = style ? [...numericCellClasses, style] : numericCellClasses;
          return {
            ...getNumericColdDef(),
            valueFormatter: (params: ValueFormatterParams<T, number>) => {
              return params.value != null ? formatter(params.value) : '';
            },
            cellClass,
          };
        }

        case RendererType.multiSelect: {
          return {
            filter: 'agTextColumnFilter',
            cellRenderer: StringListCellRenderer,
            cellRendererParams: {
              title: gridField.label ?? camelCaseToCapitalizedString(gridField.key),
            },
          };
        }

        default:
          return {
            valueFormatter: (params: ValueFormatterParams<T, boolean>) => {
              return params.value != null ? formatter(params.value) : '';
            },
          };
      }
    },
    [cellClasses, gridFormatters]
  );
}

export function useGridFormatters() {
  const currencyMap = useRecoilValue(currencyMapByIdState);
  const getCompany = useGetCompanyIfSet();
  useRecoilValue(kpiTemplatesMapByUuidState);

  return useMemo(() => {
    return {
      companyNameFromIdFormatter: <T>({ value: companyId }: ValueFormatterParams<T, number>) => {
        const company = getCompany(companyId ?? -1);
        return company?.name ?? '';
      },
      currencyFormatter: <T extends { currencyid?: number }>(params: ValueFormatterParams<T, number>) => {
        const currencyCode = currencyMap.get(params.data?.currencyid ?? -1)?.code ?? 'USD';
        if (typeof params.value !== 'number') return '';

        const formatter = FormatterService.get().getFormatterForModel({
          type: 'number',
          id: `${currencyCode}-long`,
          config: {
            currency: currencyCode,
            style: 'currency',
          },
        });

        return formatter(params.value);
      },
      currencyNameFromIdFormatter: <T>({ value }: ValueFormatterParams<T, number>) =>
        currencyMap.get(value ?? -1)?.code ?? '',
      templateNameFormatter: AgFormatterService.get().getFormatterForId<unknown, string>('kpiTemplateName'),
    };
  }, [currencyMap, getCompany]);
}

export function useGridCellClasses() {
  const currencyMap = useRecoilValue(currencyMapByIdState);

  return useMemo(() => {
    return {
      currencyClass: <T>(params: CellClassParams<T, number>) => {
        const currencyCode = currencyMap.get(params.value ?? -1)?.code ?? 'USD';

        return [getExcelClassForCurrency(currencyCode), 'monospace', 'text-align-right'];
      },
    };
  }, [currencyMap]);
}

export function useSchemaToFormFields() {
  return useCallback(schemaToFormFields, []);
}

export function schemaToFormFields<T extends Maybe<AnyObject>>(
  schema: ObjectSchema<T>,
  fieldsToShow?: NestedFieldPaths<T>[]
): IFormField<unknown>[] {
  return (fieldsToShow ?? Object.keys(schema.fields)).map((fieldId) => {
    const yupField = get(schema.fields, getSchemaFieldsPath(fieldId)).describe() as SchemaDescription;
    const formField = (yupField.meta as Partial<IFormField<unknown>>) ?? {};
    const renderer = formField.renderer ?? fieldTypeToRendererType(yupField.type as PrimitiveType);

    return createFormField({
      required: !yupField.optional,
      key: fieldId,
      label: yupField.label ?? camelCaseToCapitalizedString(fieldId),
      dataType: yupField.type as PrimitiveType,
      renderer,
      ...formField,
    });
  });
}

export function fieldTypeToRendererType(type: string): RendererType {
  switch (type) {
    case 'array':
      return RendererType.multiSelect;
    case 'boolean':
      return RendererType.boolean;
    case 'date':
      return RendererType.date;
    case 'number':
      return RendererType.number;
    case 'string':
      return RendererType.text;
    default:
      return RendererType.text;
  }
}

export function getSchemaFieldsPath(path: string) {
  const pathParts = path.split('.');
  return pathParts.join('.fields.');
}

export function generateIdDataColDefs<T>(
  formatter: (params: ValueFormatterParams<T>) => string,
  formatDataField?: boolean
): Pick<ColDef<T>, 'keyCreator' | 'valueFormatter' | 'comparator' | 'cellRenderer' | 'filterParams'> {
  return {
    keyCreator: formatter,
    valueFormatter: formatter,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      if (formatDataField) {
        return formatter({ data: nodeA.data } as ValueFormatterParams).localeCompare(
          formatter({ data: nodeB.data } as ValueFormatterParams)
        );
      }
      return formatter({ value: valueA } as ValueFormatterParams).localeCompare(
        formatter({ value: valueB } as ValueFormatterParams)
      );
    },
    cellRenderer: GenericIdRenderer,
    filterParams: {
      valueFormatter: formatter,
      keyCreator: formatter,
    },
  };
}

export function formatFieldValue<T>(fieldSchema: ISchema<T>, value?: T | null, noValue?: string): string {
  const desc = fieldSchema.describe() as SchemaDescription;
  const formatter = FormatterService.get().getFormatterForFormField(
    (desc.meta as IDisplayField<unknown>) ?? {
      formatter: 'string',
    }
  );

  return value != null ? formatter(value) : (noValue ?? '');
}

interface ILabelAndFormattedValue {
  label: string;
  formattedValue: string;
}
export function fieldDisplayValuesFromSchema<T extends AnyObject>({
  schema,
  paths,
  values,
}: {
  schema: ObjectSchema<T>;
  paths: (keyof T)[];
  values: T;
}): Map<string, ILabelAndFormattedValue> {
  return paths.reduce((map, path) => {
    const schemaField = get(schema.fields, path);
    if (!schemaField) return map;

    return map.set(String(path), {
      label:
        (schemaField.describe() as SchemaDescription)?.label ?? camelCaseToCapitalizedString(path as string),
      formattedValue: formatFieldValue(schemaField as ISchema<unknown>, get(values, path)),
    });
  }, new Map<string, ILabelAndFormattedValue>());
}

import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import {
  createInvestment,
  deleteInvestment,
  updateInvestment,
} from '../../../services/queries/MaggieTransactionQueries';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { getErrorMessage, getErrorStatusCode } from '../../../services/queryHelpers';
import { useInvalidateFinanceData } from './useInvalidateFinanceData';

export const HasAssignedTransactionsStatusCode = 409;

export function useInvestmentActions(next?: () => void) {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { invalidateAll } = useInvalidateFinanceData();

  const handleUpdateInvestment = useCallback(
    async (investment: Partial<IFinanceRoundDataModel>) => {
      try {
        const data = await updateInvestment(investment);
        invalidateAll(investment.companyId!);
        pushSuccessToast({ message: 'Investment updated' });
        return data;
      } catch (error) {
        console.error(error);
        const message = getErrorMessage(error, 'Failed to update investment');
        pushErrorToast({ message });
      } finally {
        next?.();
      }
    },
    [next, pushErrorToast, pushSuccessToast, invalidateAll]
  );

  const handleDeleteInvestment = useCallback(
    async (investment: IFinanceRoundDataModel, force = false) => {
      try {
        await deleteInvestment(investment.id, force);
        invalidateAll(investment.companyId!);
        pushSuccessToast({ message: 'Investment deleted' });
        next?.();
      } catch (error) {
        console.error(error);
        const statusCode = getErrorStatusCode(error);
        if (statusCode == HasAssignedTransactionsStatusCode) {
          return statusCode;
        }
        const message = getErrorMessage(error, 'Failed to delete investment');
        pushErrorToast({ message });
      }
    },
    [next, pushErrorToast, pushSuccessToast, invalidateAll]
  );

  const handleCreateInvestment = useCallback(
    async (investment: Partial<IFinanceRoundDataModel>) => {
      try {
        const data = await createInvestment(investment);
        invalidateAll(investment.companyId!);
        pushSuccessToast({ message: 'Investment successfully created' });
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to create investment');
        pushErrorToast({ message });
        return null;
      } finally {
        next?.();
      }
    },
    [next, pushErrorToast, pushSuccessToast, invalidateAll]
  );

  return { handleUpdateInvestment, handleDeleteInvestment, handleCreateInvestment };
}

import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';

export type MostRecentChartData = {
  amount?: number | null;
  company: ICompanyDataModel;
  date: Date;
  round?: string;
};

export const useMostRecentRounds = (data: IMetricsDataModel[]): MostRecentChartData[] => {
  const uniqueData = new Map<string, MostRecentChartData>();
  const dataCopy = [...(data ?? [])];
  const getCompany = useGetCompanyIfSet();

  const sortedData = dataCopy
    .reduce((res, { companyId }) => {
      const company = getCompany(companyId);
      if (company && company.mostRecetRoundDate && company.mostRcentRound) {
        res.push({
          company,
          date: new Date(company.mostRecetRoundDate),
          round: company.mostRcentRound,
          amount: company.lastPostMoney,
        });
      }
      return res;
    }, [] as MostRecentChartData[])
    .sort(({ date: a }, { date: b }) => {
      return b.getTime() - a.getTime();
    });

  for (let i = 0; i < sortedData.length && uniqueData.size < 5; i++) {
    uniqueData.set(sortedData[i].company.name, sortedData[i]);
  }

  return Array.from(uniqueData.values());
};

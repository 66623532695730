import * as yup from 'yup';
import { merge } from 'lodash-es';
import { RendererType } from '../data-models/field.data-model';
import {
  currencyIdField,
  dateField,
  fundTypeField,
  integerField,
  multiplierField,
  percentField,
  percentFieldHighPrecision0to100,
  usdField,
  userField,
} from './common-schema-defs';

export enum CalculationType {
  lpOnly = 'lp_only',
  combined = 'combined',
  mixed = 'mixed',
}

export function fundFields() {
  return {
    aliases: yup.array(yup.string().required()).nullable().default([]),
    calculationType: yup.string().oneOf(Object.values(CalculationType)).nullable().default(null),
    cartaId: yup.string().nullable().default(null),
    commitments: usdField().min(0).label('Commitment').nullable().required().default(null),
    contributedSecurities: yup.number().nullable().default(null),
    createdAt: yup.string().nullable().default(null),
    createdBy: yup.string().nullable().default(null),
    defaultCurrency: currencyIdField().nullable().default(null),
    enableGPCatchup: yup.boolean().nullable().default(false).label('Enable GP Catch-Up'),
    enableSuperReturn: yup.boolean().nullable().default(false),
    finalCloseDate: dateField().nullable().default(null),
    fundType: fundTypeField().default('Other'),
    generalPartners: userField().nullable().default([]),
    gpCatchUpPercentage: percentFieldHighPrecision0to100().nullable().label('GP Catch-Up').default(25),
    gpCatchUpThreshold: yup.number().nullable().default(null),
    id: yup.number().required().default(-1),
    iLevelId: yup.string().nullable().default(''),
    inceptionDate: dateField().nullable().default(null),
    initialManagementFee: percentField().nullable().default(null),
    initialPostMoney: usdField().nullable().default(null),
    stockProceeds: usdField().nullable().default(null),
    investedManagementFee: percentField().nullable().default(null),
    investmentPeriodEndDate: dateField().nullable().default(null),
    isProceedsPercentAdjusted: yup
      .boolean()
      .nullable()
      .default(false)
      .transform((value) => value ?? false)
      .label('Adjust distributable proceeds by the LP Commitment Percentage'),
    legalName: yup.string().nullable().default(null),
    lineOfCredit: usdField().nullable().default(null),
    lpCommitmentSplit: percentFieldHighPrecision0to100()
      .nullable()
      .required()
      .default(80)
      .label('LP Commitment %'),
    lpGpSplit: percentFieldHighPrecision0to100().nullable().label('Preferred Return Split').default(80),
    lpGpSplitThreshold: multiplierField().nullable().label('Preferred Return Threshold').min(0).default(3),
    managementFeeTerminationDate: dateField().nullable().default(null),
    name: yup.string().required().default(''),
    netAssets: usdField().nullable().default(null),
    reservesAllocationAmount: usdField().nullable().default(null),
    sortOrder: integerField(),
    stepDownDate: dateField().nullable().default(null),
    strategy: yup
      .array(yup.string())
      .nullable()
      .optional()
      .default([])
      .meta({
        formatter: 'stringArray',
        renderer: RendererType.multiSelect,
        rendererMeta: {
          allowCustomAdd: true,
        },
      }),
    superReturnSplit: percentFieldHighPrecision0to100().nullable().label('Super Return Split').default(20),
    taxId: yup.string().nullable().default(null),
    updatedAt: yup.string().nullable().default(null),
    updatedBy: yup.string().nullable().default(null),
    vintage: integerField()
      .min(2000)
      .max(2060)
      .meta({
        formatter: 'string',
      })
      .nullable()
      .optional()
      .default(null),
  };
}

export function fundFormSchema() {
  return yup.object().shape(fundFields());
}

export function fundProfilesFormSchema() {
  return fundFormSchema().omit(['sortOrder']);
}

export type Fund = yup.InferType<ReturnType<typeof fundFormSchema>>;

export function createFund(overrides: Partial<Fund> = {}): Fund {
  return merge({}, fundFormSchema().getDefault(), overrides);
}

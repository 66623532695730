import { useFlags } from 'launchdarkly-react-client-sdk';
import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AppLoader } from './AppLoader';
import { ROUTES } from './constants/RouteNameMapping';
import { CustomFields } from './pages/AdminPanel/CustomFields/CustomFields';
import { CompanyProfileCapTable } from './pages/CompanyProfiles/CapTable/CompanyProfileCapTable';
import { SingleCompanyCI } from './pages/CompanyProfiles/CI/SingleCompanyCI';
import { Companies } from './pages/CompanyProfiles/Companies/Companies';
import { CompanyProfilesCompetitors } from './pages/CompanyProfiles/Competitors/CompanyProfilesCompetitors';
import { CompanyProfilesPeople } from './pages/CompanyProfiles/People/CompanyProfilesPeople';
import { CompanyProfilePerformance } from './pages/CompanyProfiles/Performance/CompanyProfilePerformance';
import { CompetitiveIntelligence } from './pages/CompetitiveIntelligence/CompetitiveIntelligence';
import { DealAnalytics } from './pages/DealFlow2/components/DealAnalytics/DealAnalytics';
import { DealAnalyticsDuration } from './pages/DealFlow2/components/DealAnalytics/Details/DealAnalyticsDuration';
import { DAOverview } from './pages/DealFlow2/components/DealAnalytics/Overview/DAOverview';
import { DealFlowPreLoader, DealsView } from './pages/DealFlow2/DealFlow';
import { FinanceHistory } from './pages/FinanceHistory/FinanceHistory';
import { NotificationPreferencesContainer } from './pages/NotificationPreferences/NotificationPrefrencesContainer';
import { UI } from './pages/UI/UI';
import { Mappings } from './pages/Mappings/Mappings';
import { PermissionService } from './services/PermissionService';
import { PermissionKey, RoleKey } from './services/PermissionAndRolesKeys';
import { MaggieFeatureFlags } from './util/feature-flags';
import { ConfigDealType } from './pages/Config/ConfigDealType/ConfigDealType';
import { ConfigDealStage } from './pages/Config/ConfigDealStage/ConfigDealStage';
import { ConfigPassReasons } from './pages/Config/Reasons/ConfigPassReasons';
import { ConfigMissReasons } from './pages/Config/Reasons/ConfigMissReasons';
import { ConfigFund } from './pages/Config/Fund/ConfigFund';
import { ConfigSector } from './pages/Config/Sector/ConfigSector';
import { Finance2 } from './pages/Finance2/Finance2';
import { FinanceDashboard } from './pages/Finance2/Dashboard/FinanceDashboard';
import { Transactions } from './pages/Finance2/Transactions/Transactions';
import { FinanceRounds } from './pages/Finance2/Rounds/FinanceRounds';
import { IntegrationApps } from './pages/Integrations/IntegrationApps';
import { AirtableSync } from './pages/Integrations/Airtable/AirtableSync';
import { ConfigKPIs } from './pages/Config/KPI/ConfigKPI';
import { AdminPanel } from './pages/AdminPanel/AdminPanel';
import { TransactionHistory } from './pages/Finance2/Transactions/TransactionHistory';
import { isNotProduction } from './util/environment';
import { KPITemplate } from './pages/KPI/KPITemplate';
import { KPIRequestResponse } from './pages/KPI/components/KPIRequests/KPIRequestResponse/KPIRequestResponse';
import { CompanyProfileFinancials } from './pages/CompanyProfiles/components/Financials/CompanyProfileFinancials';
import { ScenariosWrapper } from './pages/CompanyProfiles/Scenarios/AllScenarios/ScenariosWrapper';
import { SingleScenario } from './pages/CompanyProfiles/Scenarios/SingleScenario/SingleScenario';
import { ScenarioVisualization } from './pages/CompanyProfiles/Scenarios/SingleScenario/visualization/ScenarioVisualization';
import { ScenarioForm } from './pages/CompanyProfiles/Scenarios/SingleScenario/form/ScenarioForm';
import { Security } from './pages/Finance2/Security/Security';
import { KPIRequestResponseHome } from './pages/KPI/components/KPIRequests/KPIRequestResponse/KPIRequestResponseHome';
import { ReconSection } from './pages/Finance2/Dashboard/Recon/ReconSection';
import { TransactionReconGrid } from './pages/Finance2/Dashboard/Recon/TransactionReconGrid';
import { RoundReconGrid } from './pages/Finance2/Dashboard/Recon/RoundReconGrid';
import { KPIResponsesGrid } from './pages/KPI/components/KPIRequests/KPIResponsesGrid';
import { UnsentKpiRequestsGrid } from './pages/KPI/components/KPIRequests/UnsentKpiRequestsGrid';
import { SentRequests } from './pages/KPI/components/KPIRequests/SentRequests';
import { KPIRequestStatus } from './data-models/kpi-requests.data-model';
import { KPIRecipientView } from './pages/KPI/components/KPIRequests/KPIRequestResponse/KPIRecipientView';
import { KPIThankYouScreen } from './pages/KPI/components/KPIRequests/KPIRequestResponse/KPIThankYouScreen';
import { KPICompaniesUnassignedGrid } from './pages/KPI/components/KPICompanies/KPICompaniesUnassignedGrid';
import { KPICompaniesAllAssignedGrid } from './pages/KPI/components/KPICompanies/KPICompaniesAllAssignedGrid';
import { CaptableFormWrapper } from './pages/CompanyProfiles/CapTable/CapTableForm';
import { CompanyProfiles2 } from './pages/CompanyProfiles2/CompanyProfiles2';
import { CompanySummary2 } from './pages/CompanyProfiles2/Summary/CompanySummary2';
import { ConfigKPIGroups } from './pages/Config/KPIGroups/ConfigKPIGroups';
import { AllTransactions } from './pages/Finance2/AllTransactions/AllTransactions';
import { AllCompanies } from './pages/KPI/components/KPICompanies/AllCompanies';
import { AssignedCompanies } from './pages/KPI/components/KPICompanies/AssignedCompanies';
import { KPIRecipientView2 } from './pages/KPI/components/KPIRequests/KPIRequestResponse/KPIRecipientView2';
import { DataDisplay, DataDisplayContent } from './pages/AdminPanel/DataDisplay/DataDisplay';
import { CompanyDetailsConfig } from './pages/AdminPanel/DataDisplay/CompanyDetailsConfig';
import { TransactionsTable3 } from './pages/Finance2/Transactions3/Transactions3';
import { KpiLoader } from './pages/KPI/KpiLoader';
import { LoginSuccess } from './pages/LoginSuccess/LoginSuccess';
import { InvestmentMetricsConfig } from './pages/AdminPanel/DataDisplay/InvestmentMetricsConfig';
import { AllSecurities } from './pages/Finance2/AllSecurities/AllSecurities';
import { FundProfiles } from './pages/FundProfiles/FundProfiles';
import { FPSummary } from './pages/FundProfiles/Summary/FPSummary';
import { FPWaterfall } from './pages/FundProfiles/Waterfall/FPWaterfall';
import { RubricsConfigLoader } from './pages/AdminPanel/Rubrics/RubricsConfig';
import { KPIResponses } from './pages/KPI/components/KPIRequests/KPIRequestsAndResponses';
import { GeneralLedgerLoader } from './pages/AdminPanel/GeneralLedger/GeneralLedger';
import { FundReserves } from './pages/FundProfiles/Reserves/FundReserves';
import { BulkUploadReportLoader } from './pages/AdminPanel/BulkUploadReport/BulkUploadReport';

const KPIRequestsAndResponses = lazy(
  () => import('./pages/KPI/components/KPIRequests/KPIRequestsAndResponses')
);
const KPICompanies = lazy(() => import('./pages/KPI/components/KPICompanies/KPICompanies'));
const PortfolioOverview = lazy(() => import('./pages/PortfolioOverview/PortfolioOverview'));
const CompanyComparison = lazy(() => import('./pages/CompanyComparison/CompanyComparison'));
const GrowthAnalysis = lazy(() => import('./pages/GrowthAnalysis/GrowthAnalysis'));

export function RoutedApp() {
  const {
    competitiveIntelligenceModule,
    enableResponseAutosave,
    showAllSecurities,
    showAllTransactions,
    showBulkUploadStatusUpdates,
    showCapTable,
    showDataCollectionCompanies2,
    showDataCollectionModule,
    showDataDisplayConfig,
    showFinanceSecurity,
    showFinance2,
    showFundProfiles,
    showFundReserves,
    showGeneralLedger,
    showIntegrations,
    showManageGroups,
    showMappingSection,
    showNotifications,
    showReconciliation,
    showRubricConfig,
    showMetricsRecon,
    showScenarioModelling,
    showTransactionV3AsPrimary,
  } = useFlags<MaggieFeatureFlags>();
  const permSvc = PermissionService.get();
  const conditionalRoutes: JSX.Element[] = [];

  if (competitiveIntelligenceModule) {
    conditionalRoutes.push(
      <Route
        key={ROUTES.COMPETITIVE_INTELLIGENCE}
        path={ROUTES.COMPETITIVE_INTELLIGENCE}
        element={<CompetitiveIntelligence />}
      />
    );
  } else {
    conditionalRoutes.push(
      <Route
        key={ROUTES.COMPETITIVE_INTELLIGENCE}
        path={ROUTES.COMPETITIVE_INTELLIGENCE}
        element={<RouteNotAllowed />}
      />
    );
  }

  if (permSvc.hasPermission(PermissionKey.canViewTransaction)) {
    conditionalRoutes.push(
      <Route key={ROUTES.FINANCE_HISTORY} path={ROUTES.FINANCE_HISTORY} element={<FinanceHistory />} />
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.FINANCE} path={ROUTES.FINANCE} element={<RouteNotAllowed />} />,
      <Route key={ROUTES.FINANCE_HISTORY} path={ROUTES.FINANCE_HISTORY} element={<RouteNotAllowed />} />
    );
  }

  if (showFundProfiles) {
    conditionalRoutes.push(
      <Route key={ROUTES.FUND_PROFILES} path={`${ROUTES.FUND_PROFILES}/:fundId`} element={<FundProfiles />}>
        <Route index element={<Navigate to={ROUTES.FP_SUMMARY} replace />} />
        <Route path={ROUTES.FP_SUMMARY} element={<FPSummary />} />
        <Route path={ROUTES.FP_WATERFALL} element={<FPWaterfall />} />
        {showFundReserves && <Route path={ROUTES.FP_RESERVES} element={<FundReserves />} />}
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.FUND_PROFILES} path={ROUTES.FUND_PROFILES} element={<RouteNotAllowed />} />
    );
  }

  if (showFinance2 && permSvc.hasPermission(PermissionKey.canViewTransaction)) {
    conditionalRoutes.push(
      <Route key={ROUTES.FINANCE2} path={ROUTES.FINANCE2} element={<Finance2 />}>
        <Route path={ROUTES.FINANCE2_OVERVIEW} element={<Outlet />}>
          {showAllTransactions ? (
            <Route index element={<AllTransactions />} />
          ) : (
            <Route index element={<FinanceDashboard />} />
          )}
          <Route path={ROUTES.FINANCE_DETAILS} element={<Outlet />}>
            {showFinanceSecurity && <Route path={ROUTES.FINANCE_SECURITY} element={<Security />} />}
            <Route path={ROUTES.FINANCE_TRANSACTIONS} element={<Outlet />}>
              <Route index element={<Transactions />} />
              <Route path={ROUTES.FINANCE_TRANSACTION_HISTORY} element={<TransactionHistory />} />
            </Route>
            <Route path={ROUTES.FINANCE_ROUNDS} element={<FinanceRounds />} />
            {showTransactionV3AsPrimary && [
              <Route
                key={ROUTES.FINANCE_TRANSACTIONS_3}
                path={ROUTES.FINANCE_TRANSACTIONS_3}
                element={<Outlet />}
              >
                <Route index element={<TransactionsTable3 />} />
                <Route path={ROUTES.FINANCE_TRANSACTION_HISTORY} element={<TransactionHistory />} />
              </Route>,
            ]}
          </Route>
        </Route>
        {showAllSecurities && <Route path={ROUTES.FINANCE_ALL_SECURITIES} element={<AllSecurities />} />}
        {showReconciliation && (
          <Route path={ROUTES.FINANCE_RECON} element={<ReconSection />}>
            <Route index element={<RoundReconGrid />} />
            {showMetricsRecon && (
              <Route path={ROUTES.FINANCE_RECON_METRICS} element={<TransactionReconGrid />} />
            )}
          </Route>
        )}
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.FINANCE} path={ROUTES.FINANCE2} element={<RouteNotAllowed />}>
        <Route path={ROUTES.FINANCE_ROUNDS} element={<RouteNotAllowed />} />
        <Route path={ROUTES.FINANCE_TRANSACTIONS} element={<RouteNotAllowed />} />
      </Route>
    );
  }

  if (showMappingSection) {
    conditionalRoutes.push(<Route key={ROUTES.MAPPINGS} path={ROUTES.MAPPINGS} element={<Mappings />} />);
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.MAPPINGS} path={ROUTES.MAPPINGS} element={<RouteNotAllowed />} />
    );
  }

  if (showIntegrations) {
    conditionalRoutes.push(
      <Route key={ROUTES.INTEGRATIONS} path={ROUTES.INTEGRATIONS} element={<Outlet />}>
        <Route path={ROUTES.INTEGRATIONS_APPS} element={<IntegrationApps />}>
          <Route path={ROUTES.INTEGRATIONS_APPS_AIRTABLE} element={<AirtableSync />} />
        </Route>
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.INTEGRATIONS} path={ROUTES.INTEGRATIONS} element={<RouteNotAllowed />}>
        <Route path={ROUTES.INTEGRATIONS_APPS} element={<RouteNotAllowed />}>
          <Route path={ROUTES.INTEGRATIONS_APPS_AIRTABLE} element={<RouteNotAllowed />} />
        </Route>
      </Route>
    );
  }

  if (showDataCollectionModule) {
    conditionalRoutes.push(
      <Route key={ROUTES.KPI} path={ROUTES.KPI} element={<KpiLoader />}>
        {permSvc.hasPermission(PermissionKey.canReadDataCollection) && (
          <>
            <Route path={ROUTES.KPI_REQUESTS} key={ROUTES.KPI_REQUESTS} element={<KPIRequestsAndResponses />}>
              <Route index element={<UnsentKpiRequestsGrid />} />
              <Route
                path={ROUTES.KPI_REQUESTS_IN_PROGRESS}
                element={<SentRequests status={KPIRequestStatus.Sent} />}
              />
              <Route
                path={ROUTES.KPI_REQUESTS_ARCHIVED}
                element={<SentRequests status={KPIRequestStatus.Archived} />}
              />
            </Route>

            <Route path={ROUTES.KPI_RESPONSES} element={<KPIResponses />}>
              <Route index element={<KPIResponsesGrid status={KPIRequestStatus.Submitted} />} />
              <Route
                path={ROUTES.KPI_RESPONSES_ACCEPTED}
                element={<KPIResponsesGrid status={KPIRequestStatus.Accepted} />}
              />
              <Route
                path={ROUTES.KPI_RESPONSES_REJECTED}
                element={<KPIResponsesGrid status={KPIRequestStatus.Rejected} />}
              />
            </Route>

            <Route path={ROUTES.COMPANIES} element={<KPICompanies />}>
              <Route
                index
                key={ROUTES.COMPANIES}
                element={showDataCollectionCompanies2 ? <AllCompanies /> : <KPICompaniesUnassignedGrid />}
              />
              <Route
                path={ROUTES.ASSIGNED_COMPANIES}
                element={
                  showDataCollectionCompanies2 ? <AssignedCompanies /> : <KPICompaniesAllAssignedGrid />
                }
              />
            </Route>
            <Route path={`${ROUTES.KPI_TEMPLATES}/:templateId`} element={<KPITemplate />} />
          </>
        )}
      </Route>
    );
  } else {
    conditionalRoutes.push(<Route key={ROUTES.KPI} path={ROUTES.KPI} element={<RouteNotAllowed />} />);
  }

  if (showBulkUploadStatusUpdates) {
    conditionalRoutes.push(
      <Route
        key={ROUTES.BULK_UPLOAD_REPORT}
        path={`${ROUTES.BULK_UPLOAD_REPORT}/:uploadId`}
        element={<BulkUploadReportLoader />}
      />
    );
  }
  conditionalRoutes.push(
    <Route key={ROUTES.ADMIN_PANEL} path={ROUTES.ADMIN_PANEL} element={<AdminPanel />}>
      <Route path={ROUTES.ADMIN_PANEL_FIELDS} element={<CustomFields />} />
      {showDataDisplayConfig && (
        <Route path={ROUTES.ADMIN_PANEL_DATA_DISPLAY} element={<DataDisplay />}>
          <Route index element={<DataDisplayContent />} />
          <Route path={ROUTES.DETAILS_CONFIG} element={<CompanyDetailsConfig />} />
          <Route path={ROUTES.METRICS_CONFIG} element={<InvestmentMetricsConfig />} />
        </Route>
      )}
      {showRubricConfig && <Route path={ROUTES.ADMIN_PANEL_RUBRICS} element={<RubricsConfigLoader />} />}
      {showNotifications && permSvc.hasPermission(PermissionKey.canViewDeal) && (
        <Route path={ROUTES.NOTIFICATION_PREFERENCES} element={<NotificationPreferencesContainer />} />
      )}
      <Route path={ROUTES.CONFIG}>
        <Route path={ROUTES.CONFIG_DEAL_TYPES} element={<ConfigDealType />} index />
        <Route path={ROUTES.CONFIG_DEAL_STAGES} element={<ConfigDealStage />} />
        <Route path={ROUTES.CONFIG_PASS_REASONS} element={<ConfigPassReasons />} />
        <Route path={ROUTES.CONFIG_MISS_REASONS} element={<ConfigMissReasons />} />
        <Route path={ROUTES.CONFIG_SECTORS} element={<ConfigSector />} />
        <Route path={ROUTES.CONFIG_FUNDS} element={<ConfigFund />} />
        <Route path={ROUTES.CONFIG_KPI} element={<ConfigKPIs />} />
        {showManageGroups && <Route path={ROUTES.CONFIG_KPI_GROUPS} element={<ConfigKPIGroups />} />}
        {showGeneralLedger && <Route path={ROUTES.GENERAL_LEDGER} element={<GeneralLedgerLoader />} />}
      </Route>
    </Route>
  );

  if (permSvc.hasPermission(PermissionKey.canViewList)) {
    conditionalRoutes.push(
      <Route key={ROUTES.COMPANIES} path={ROUTES.COMPANIES} element={<Companies />}>
        <Route path={`:listId`} element={<Companies />} />
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.COMPANIES} path={ROUTES.COMPANIES} element={<RouteNotAllowed />}>
        <Route path={`:listId`} element={<RouteNotAllowed />} />
      </Route>
    );
  }

  if (permSvc.hasPermission(PermissionKey.canViewMetrics)) {
    conditionalRoutes.push(
      <Route key={ROUTES.PORTFOLIO} path={`${ROUTES.PORTFOLIO}`} element={<PortfolioOverview />}>
        <Route path={`:viewId`} element={<PortfolioOverview />} />
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.PORTFOLIO} path={ROUTES.PORTFOLIO} element={<RouteNotAllowed />}>
        <Route path={`:viewId`} element={<RouteNotAllowed />} />
      </Route>
    );
  }

  if (permSvc.hasPermission(PermissionKey.canReadDataCollection)) {
    conditionalRoutes.push(
      <Route
        key={ROUTES.KPI_REQUEST_REQUESTID_RESPONSE}
        path={ROUTES.KPI_REQUEST_REQUESTID_RESPONSE}
        element={<KPIRequestResponse />}
      />
    );
  }

  if (permSvc.hasPermission(PermissionKey.canViewDeal)) {
    conditionalRoutes.push(
      <Route key={ROUTES.DEAL_FLOW} path={ROUTES.DEAL_FLOW} element={<Outlet />}>
        <Route path={ROUTES.DEAL_ANALYTICS} element={<DealAnalytics />}>
          <Route path={ROUTES.DEAL_ANALYTICS_KPI} element={<DAOverview />} />
          <Route path={ROUTES.DEAL_ANALYTICS_DURATION_DETAILS} element={<DealAnalyticsDuration />} />
        </Route>

        <Route path={`${ROUTES.DEALS}?`} element={<DealFlowPreLoader />}>
          <Route path={`:dealCategory`} element={<DealsView />} />
        </Route>
      </Route>
    );
  } else {
    conditionalRoutes.push(
      <Route key={ROUTES.DEAL_FLOW} path={ROUTES.DEAL_FLOW} element={<RouteNotAllowed />}>
        <Route path={ROUTES.DEAL_ANALYTICS} element={<RouteNotAllowed />}>
          <Route path={ROUTES.DEAL_ANALYTICS_KPI} element={<RouteNotAllowed />} />
          <Route path={ROUTES.DEAL_ANALYTICS_DURATION_DETAILS} element={<RouteNotAllowed />} />
        </Route>
        <Route path={`${ROUTES.DEALS}?`} element={<RouteNotAllowed />}>
          <Route path={`:dealCategory`} element={<RouteNotAllowed />} />
        </Route>
      </Route>
    );
  }

  if (
    permSvc.hasRole(RoleKey.externalUser) &&
    (permSvc.hasPermission(PermissionKey.canSubmitCollectionSubmit) ||
      permSvc.hasPermission(PermissionKey.canReadDataCollection))
  ) {
    return (
      <Routes>
        <Route path={'/'} element={<AppLoader />}>
          <Route
            key={ROUTES.KPI_THANK_YOU_SCREEN}
            path={ROUTES.KPI_THANK_YOU_SCREEN}
            element={<KPIThankYouScreen />}
          />
          <Route
            key={ROUTES.KPI_REQUESTS_RESPONSES_HOME}
            path={ROUTES.KPI_REQUESTS_RESPONSES_HOME}
            element={<KPIRequestResponseHome />}
          />
          <Route
            key={ROUTES.KPI_REQUEST_REQUESTID_RESPONSE}
            path={ROUTES.KPI_REQUEST_REQUESTID_RESPONSE}
            element={<KPIRequestResponse />}
          />
          <Route
            key={ROUTES.KPI_PENDING_REQUESTS}
            path={ROUTES.KPI_PENDING_REQUESTS}
            element={enableResponseAutosave ? <KPIRecipientView2 /> : <KPIRecipientView />}
          />
          <Route path='*' element={<div />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={'/signout'} element={<Navigate replace to='/' />} />

      <Route path={'/'} element={<AppLoader />} errorElement={<div>Route not found</div>}>
        <Route index element={<IndexComponent />} />
        <Route path={ROUTES.EXCEL_LOGIN} element={<LoginSuccess />} />

        {isNotProduction() && <Route path={ROUTES.UI} element={<UI />} />}
        {isNotProduction() && <Route path={'cap'} element={<UI />} />}
        <Route path={ROUTES.COMPANY_COMPARISON} element={<CompanyComparison />} />

        <Route path={ROUTES.COMPANY_PROFILES}>
          <Route path={`:id`} element={<CompanyProfiles2 />}>
            <Route path={ROUTES.COMPANY_PROFILE_SUMMARY} element={<CompanySummary2 />} />
            <Route path={ROUTES.COMPANY_PROFILE_PERFORMANCE} element={<CompanyProfilePerformance />} />
            <Route path={ROUTES.COMPANY_PROFILE_COMPETITORS} element={<CompanyProfilesCompetitors />} />
            <Route path={ROUTES.COMPANY_PROFILE_PEOPLE} element={<CompanyProfilesPeople />} />
            <Route path={ROUTES.COMPANY_PROFILE_CI} element={<SingleCompanyCI />} />
            <Route path={ROUTES.COMPANY_PROFILE_FINANCIALS} element={<CompanyProfileFinancials />} />
            {showCapTable && permSvc.hasPermission(PermissionKey.canViewTransaction) && (
              <Route path={ROUTES.COMPANY_PROFILE_CAP_TABLE} element={<Outlet />}>
                <Route index element={<CompanyProfileCapTable />} />
                <Route path={ROUTES.CAP_TABLE_FORM} element={<CaptableFormWrapper />} />
              </Route>
            )}
            {showScenarioModelling && (
              <Route path={ROUTES.COMPANY_PROFILE_SCENARIOS} element={<Outlet />}>
                <Route index element={<ScenariosWrapper />} />
                <Route path={ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO} element={<SingleScenario />}>
                  <Route path={ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS} element={<ScenarioForm />} />
                  <Route path={ROUTES.COMPANY_PROFILE_SCENARIO_RUN} element={<ScenarioVisualization />} />
                </Route>
              </Route>
            )}
          </Route>
        </Route>
        <Route path={ROUTES.GROWTH_ANALYSIS} element={<GrowthAnalysis />} />
        {conditionalRoutes}
      </Route>
    </Routes>
  );
}

function IndexComponent() {
  const permSvc = PermissionService.get();

  if (permSvc.hasPermission(PermissionKey.canViewMetrics)) {
    return <PortfolioOverview />;
  }
  if (permSvc.hasPermission(PermissionKey.canViewList)) {
    return <Companies />;
  }
  return <div />;
}

function RouteNotAllowed() {
  return <div>You do not have access to this route</div>;
}

import { FC, useCallback, useMemo, useState } from 'react';
import { Alert, Collapse, Typography, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useInvestmentActions } from '../../hooks/useInvestmentActions';
import { financeTransactionsByCompanyState } from '../../state/FinanceState';
import { pluralizeWord } from '../../../../util/stringUtils';
import { IInvestmentFormModalProps } from './EditInvestmentModal';

export const DeleteInvestmentModal: FC<IInvestmentFormModalProps> = ({ open, onClose, investment }) => {
  const { handleDeleteInvestment } = useInvestmentActions();
  const transactions = useRecoilValue(financeTransactionsByCompanyState(investment.companyId));
  const nAttachedTransactions = useMemo(
    () =>
      transactions.reduce((sum, t) => {
        if (t.investmentRoundId === investment.id) {
          return sum + 1;
        } else {
          return sum;
        }
      }, 0),
    [transactions, investment.id]
  );
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await handleDeleteInvestment(investment, nAttachedTransactions > 0);
    setLoading(false);
    onClose();
  }, [nAttachedTransactions, onClose, handleDeleteInvestment, investment]);

  return open ? (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title='Are you sure you want to delete this round?'
      loading={loading}
    >
      <Typography variant='body2' color={colors.neutral[60]}>
        {`The round ${investment.name ?? ''} will be deleted permanently`}
      </Typography>
      <Collapse in={nAttachedTransactions > 0} sx={nAttachedTransactions ? { mt: '1rem' } : undefined}>
        {nAttachedTransactions ? (
          <>
            <Alert severity='warning' sx={{ mb: '0.5rem' }}>
              {`This round has ${nAttachedTransactions} ${pluralizeWord('transaction')} attached, are you sure you would like to delete it?`}
              <br />
              You can reassign the transactions to another existing round.
            </Alert>
          </>
        ) : (
          <div style={{ height: '2rem' }} />
        )}
      </Collapse>
    </ConfirmDeletionDialog>
  ) : (
    <div style={{ height: '1rem' }} />
  );
};

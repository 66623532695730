import {
  CsvExportParams,
  ExcelExportParams,
  ExcelStyle,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import { merge } from 'lodash-es';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RendererType } from '../../data-models/field.data-model';
import { getISODate } from '../../services/queries/MaggieMetricsQueries';
import { currenciesState } from '../../services/state/AppConfigState';
import { StandardFormatterId } from '../../util/formatter-service';
import { DateFormattersId } from '../../util/formatters/DateFormatters';
import { sanitizeAsFileName } from '../../util/stringUtils';

export function commonProcessCb(params: ProcessCellForExportParams) {
  const val = params.value;
  if (val === undefined || val === null) return '';

  if (val instanceof Date) {
    // Return the raw date from the BE which will be in ISO date format YYYY-MM-DD
    return params.node?.data?.[params.column.getColId()];
  }

  const type = params.column.getColDef().type;
  const cellClass = [params.column.getColDef().cellClass].flat();
  if (type === 'percent' || type?.includes('percent') || cellClass?.includes('percent')) {
    return processValueForExport(val, 'percent2dpAsIs');
  }

  return val;
}

export const defaultExcelStyles: ExcelStyle[] = [
  { id: 'default', dataType: 'String' },
  {
    id: 'dateISO', // id is the cellClass column property
    dataType: 'DateTime',
    numberFormat: { format: 'mm/dd/yyyy;;;' },
  },
  {
    id: 'percent',
    numberFormat: { format: '#,##0.00%' },
  },
  {
    id: 'numberTwoDecimals',
    numberFormat: {
      format: '#,##0.00',
    },
  },
  {
    id: 'currencyUSD',
    numberFormat: {
      format: '$#,##0.00',
    },
  },
  {
    id: 'currencyUSDRound',
    numberFormat: {
      format: '$#,##0',
    },
  },
  {
    id: 'int',
    numberFormat: {
      format: '#,##0',
    },
  },
  {
    id: 'multiplier',
    numberFormat: {
      format: '#,##0.00x',
    },
  },
];

// returns excel styles for all supported currencies
export function useExcelStylesForCurrencies() {
  const currencies = useRecoilValue(currenciesState);

  if (!currencies) return [];
  return currencies.map((currency) => ({
    id: getExcelClassForCurrency(currency.code),
    numberFormat: {
      format: `${currency.symbol}#,##0.00`,
    },
  }));
}

export function getExcelClassForCurrency(currencyCode: string) {
  return `currency${currencyCode}`;
}
export function useExportToExcelStyles() {
  const currencyStyles = useExcelStylesForCurrencies();
  5;
  return [...defaultExcelStyles, ...currencyStyles];
}

export const rendererTypeToExcelStyleId: Partial<Record<RendererType, string>> = {
  [RendererType.date]: 'dateISO',
  [RendererType.percent]: 'percent',
  [RendererType.number]: 'numberTwoDecimals',
  [RendererType.currency]: 'currencyUSD',
  [RendererType.integer]: 'int',
  [RendererType.multiplier]: 'multiplier',
  [RendererType.text]: 'default',
};

export const formatterIdToExcelStyleId: Partial<Record<StandardFormatterId, string>> = {
  usd: 'currencyUSD',
  usdShort: 'currencyUSD',
  percent2dpAsIs: 'percent',
  integer: 'int',
  multiplier: 'multiplier',
  date: 'dateISO',
};

/**
 *
 * @param colIds set of column ids (`column.colId`) that should be exported with custom formatting,
 * by calling the defined valueFormatter for each column
 * @returns  export params that can be passed as defaultExcelExportParams or defaultCsvExportParams
 */
export function useExtendedExportSettings<T>(colIds: Set<string>, fileName?: string) {
  const exportParams: ExcelExportParams & CsvExportParams = useMemo(() => {
    function processCb(params: ProcessCellForExportParams<T>) {
      const colId = params.column.getColId();
      if (colIds.has(colId)) {
        return params.formatValue(params.value);
      }
      return commonProcessCb(params);
    }

    return {
      processCellCallback: processCb,
      fileName,
    };
  }, [colIds, fileName]);

  return { exportParams };
}

export function getExportParams(params: Partial<ExcelExportParams | CsvExportParams> = {}) {
  let fileName = params?.fileName;
  if (fileName && typeof fileName === 'string') {
    fileName = sanitizeAsFileName(fileName!);
    return merge({}, params, { fileName });
  }
  return params;
}

const dateFormatters = new Set<string>(Object.values(DateFormattersId));
export function processValueForExport(value: unknown, format?: StandardFormatterId | string) {
  switch (true) {
    case dateFormatters.has(format ?? '') && value instanceof Date: {
      return getISODate(value);
    }
    case format === 'percent2dpAsIs' && typeof value === 'number': {
      return value / 100;
    }
    default:
      return value;
  }
}

import * as yup from 'yup';
import { merge } from 'lodash-es';
import { multiplierField, usdField } from './common-schema-defs';

export const TransactionPeriodicity = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Annually: 'annually',
} as const;
export type TransactionPeriodicity = (typeof TransactionPeriodicity)[keyof typeof TransactionPeriodicity];

export const InvestmentType = {
  Initial: 'initial-investment',
  FollowOn: 'follow-on-investment',
} as const;
export type InvestmentType = (typeof InvestmentType)[keyof typeof InvestmentType];

export const investmentTypeToLabel = {
  [InvestmentType.Initial]: 'Initial',
  [InvestmentType.FollowOn]: 'Follow-On',
};

export function fundReservesFields() {
  return {
    investments: yup.array().of(yup.object(fundInvestmentFields())),
    summary: yup.object().shape(fundReservesSummaryFields()),
  };
}

export function fundReservesSchema() {
  return yup.object().shape(fundReservesFields());
}
export type FundReserves = yup.InferType<ReturnType<typeof fundReservesSchema>>;
export function createFundReserves(overrides: Partial<FundReserves> = {}): FundReserves {
  return merge({}, fundReservesSchema().getDefault(), overrides);
}

export function fundReservesSummaryFields() {
  return {
    assignedReserves: usdField(),
    projectedAssignedReservesAmount: usdField(),
    projectedReservesBalance: usdField(),
    projectedReservesRatio: multiplierField(),
    projectedReservesRequired: usdField(),
    reservesAllocation: usdField(),
    reservesAllocationRemaining: usdField(),
    reservesInvested: usdField(),
    unassignedReserves: usdField(),
  };
}

export function fundReservesSummarySchema() {
  return yup.object().shape(fundReservesSummaryFields());
}
export type FundReservesSummary = yup.InferType<ReturnType<typeof fundReservesSummarySchema>>;
export function createFundReservesSummary(overrides: Partial<FundReservesSummary> = {}): FundReservesSummary {
  return merge({}, fundReservesSummarySchema().getDefault(), overrides);
}

export function fundInvestmentFields() {
  return {
    assignedReservesAmount: usdField().nullable(),
    companyId: yup.number().nullable(), // company id for portfolio companies
    name: yup.string().nullable(), // name for hypothetical companies
    fundId: yup.number().nullable().required(),
    transactions: yup.array().of(yup.object(fundInvestmentTransactionFields())).default([]),
  };
}

export function fundInvestmentSchema() {
  return yup.object().shape(fundInvestmentFields());
}
export type FundInvestment = yup.InferType<ReturnType<typeof fundInvestmentSchema>>;
export function createFundInvestment(overrides: Partial<FundInvestment> = {}): FundInvestment {
  return merge({}, { ...fundInvestmentSchema().getDefault(), fundId: 0 }, overrides);
}

export function fundInvestmentTransactionFields() {
  return {
    amount: usdField(),
    date: yup.date().nullable(),
    hypothetical: yup.boolean().nullable(),
    periodicity: yup.string().nullable().oneOf(Object.keys(TransactionPeriodicity)),
    type: yup.string().nullable().oneOf(Object.keys(InvestmentType)),
    uuid: yup.string().nullable(),
  };
}
export function fundInvestmentTransactionSchema() {
  return yup.object().shape(fundInvestmentTransactionFields());
}
export type FundInvestmentTransaction = yup.InferType<ReturnType<typeof fundInvestmentTransactionSchema>>;
export function createFundInvestmentTransaction(
  overrides: Partial<FundInvestmentTransaction> = {}
): FundInvestmentTransaction {
  return merge({}, fundInvestmentTransactionSchema().getDefault(), overrides);
}

import { useMemo } from 'react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Stack, Typography } from '@mui/material';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { withOverrides } from '../../../util/ag-grid-utils';
import { colors } from '../../../theme/colors';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { ReservesRowData, ReservesRowDataSchema } from './reservesGridData';

export function useReservesColDefs(hypothetical?: boolean) {
  const schemaToGrid = useSchemaToGrid();

  return useMemo((): ColDef<ReservesRowData>[] => {
    const schema = ReservesRowDataSchema();

    const overrides: Partial<Record<keyof ReservesRowData, ColDef<ReservesRowData>>> = {
      reservesUsed: {
        initialHide: true,
      },
    };
    if (hypothetical) {
      overrides.companyName = {
        field: 'companyName',
        initialRowGroup: true,
        initialHide: true,
        sortable: true,
        sortIndex: 0,
      };
    } else {
      overrides.companyId = {
        initialRowGroup: true,
        initialHide: true,
        cellRendererParams: {
          displayLogoInGroupRow: true,
        },
        sortable: true,
        initialSort: 'asc',
        sortIndex: 0,
      };
    }
    const colDefs = schemaToGrid(schema, [
      hypothetical ? 'companyName' : 'companyId',
      'type',
      'date',
      'investmentAmount',
      'initialInvestment',
      'reservesUsed',
      'reservesInvested',
      'reservesAssigned',
      // TODO: leave out for now - prob. can work if we unify everything in one grid
      // 'reservesAllocated',
      // 'reservesRemaining',
    ]);
    return withOverrides(colDefs, overrides) as ColDef<ReservesRowData>[];
  }, [schemaToGrid, hypothetical]);
}

export const defaultReservesColDefs: ColDef<ReservesRowData> = {
  flex: 1,
  minWidth: 200,
};

export function ReservesRenderer(params: CustomCellRendererProps<ReservesRowData>) {
  if (params.node?.isExpandable()) return null;
  const { value, valueFormatted } = params;
  if (value == null) return null;
  if (value >= 0) return valueFormatted ?? value;

  return (
    <Stack display={'grid'} height='100%' width='100%' alignContent={'center'}>
      <Typography
        variant='body2'
        color='error'
        sx={{
          background: colors.critical[5],
          width: 'fit-content',
          justifySelf: 'end',
          borderRadius: '4px',
          border: `1px dashed ${colors.critical[30]}`,
          paddingInline: '0.2rem',
        }}
      >
        {params.valueFormatted ?? params.value}
      </Typography>
    </Stack>
  );
}

export const defaultReservesColGroupDef = {
  headerName: 'Company',
  filter: true,
};

export function useDefaultReservesColGroupDef(hypothetical: boolean): ColDef<ReservesRowData> {
  const getCompanyIfSet = useGetCompanyIfSet();
  return useMemo(() => {
    return {
      headerName: 'Company',
      filter: true,
      filterValueGetter: (params: ValueGetterParams) => {
        if (!params.data) return null;
        if (hypothetical) return params.data.companyName ?? '';
        return getCompanyIfSet(params.data.companyId)?.name ?? '';
      },
      comparator: (a: number | string, b: number | string) => {
        if (hypothetical) return ((a as string) ?? '').localeCompare((b as string) ?? '');
        return (getCompanyIfSet(a as number)?.name ?? '').localeCompare(
          getCompanyIfSet(b as number)?.name ?? ''
        );
      },
    };
  }, [getCompanyIfSet, hypothetical]);
}

import { css } from '@emotion/react';
import { GetRowIdParams } from 'ag-grid-community';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { colors } from '../../../../../theme/colors';
import { companyProfileFinancialsDefaultColDef } from '../../../commonGridColDefs';
import { selectedEndDateFinancialsState } from '../../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { getEachDateByPeriodAndFrequency } from '../../../utils/financialUtils';
import { useFinancialsColumnDefs } from '../hooks/useFinancialsColumnDefs';
import { IFinancialsRowData2, useFinancialsGridData } from '../hooks/useFinancialsGridData';
import { useFinancialsGridRowHeight } from '../hooks/useFinancialsGridRowHeight';
import { PinnedYearColumnTag } from '../pinnedYears/usePinnedYearColDefs';
import { useVisibleColumns } from '../pinnedYears/useVisibleColumns';
import { useFinancialsGridSettings } from './FinancialsSettingsState';
import { useFinancialsSortState } from './financialsSortState';

const rowBg = `${colors.primary[0]}55`;

export const finTableStyles = css`
  padding-top: 1rem;
  height: 70vh;
  --ag-borders: none;
  .ag-root-wrapper {
    --ag-row-background-color: ${rowBg};
    --ag-odd-row-background-color: ${rowBg};
    --ag-background-color: ${rowBg};
    // we can remove this if we decide to pass columnBorder: true to theme
    .ag-cell {
      border-right: 1px solid ${colors.neutral[20]};
    }
    .ag-pinned-right-cols-container {
      background: ${colors.primary[20]}22;
    }
    .${PinnedYearColumnTag} {
      background: ${colors.primary[20]}88;
      &:hover {
        background-color: ${colors.primary[20]}99 !important;
      }
    }
  }
  .centered-header .ag-header-cell-label {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
`;

export type CompanyProfileFinancialsTableProps = {
  totalSelectedPeriods: number;
  frequency: KpiPeriod;
  fye: number;
};

export function CompanyProfileFinancialsTable({
  totalSelectedPeriods,
  frequency,
  fye,
}: CompanyProfileFinancialsTableProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedEndDate = useRecoilValue(selectedEndDateFinancialsState(companyId));
  const allPeriods = useMemo(
    () => getEachDateByPeriodAndFrequency({ totalSelectedPeriods, frequency, fye, endDate: selectedEndDate }),
    [totalSelectedPeriods, frequency, fye, selectedEndDate]
  );

  const { gridApi, onGridReady, onGridPreDestroyed, onColumnVisible } = useVisibleColumns();
  const { displaySettings } = useFinancialsGridSettings();

  const getRowData = useFinancialsGridData();
  const rowData = useMemo(
    () => getRowData(frequency, displaySettings),
    [getRowData, frequency, displaySettings]
  );

  const { columnDefs } = useFinancialsColumnDefs({
    allPeriods: allPeriods.dates,
    frequency,
    options: displaySettings,
  });

  const { getRowHeight } = useFinancialsGridRowHeight(gridApi, displaySettings);

  const getRowId = useCallback((params: GetRowIdParams<IFinancialsRowData2>) => {
    return String(params.data?.id);
  }, []);

  const { onSortChanged, resetSort } = useFinancialsSortState();
  useEffect(() => {
    return resetSort;
  }, [resetSort]);

  return (
    <div css={finTableStyles}>
      <AgTable
        columnDefs={columnDefs}
        defaultColDef={companyProfileFinancialsDefaultColDef}
        groupDefaultExpanded={-1}
        groupDisplayType='groupRows'
        grandTotalRow={'bottom'}
        rowData={rowData}
        rowGroupPanelShow='never'
        suppressMovableColumns={true}
        suppressRowDrag={true}
        getRowHeight={getRowHeight}
        suppressScrollOnNewData
        suppressCsvExport
        suppressExcelExport
        getRowId={getRowId}
        onSortChanged={onSortChanged}
        suppressColumnMoveAnimation // prevent pinned menu popover from being misplaced
        onGridReady={onGridReady}
        onGridPreDestroyed={onGridPreDestroyed}
        onColumnVisible={onColumnVisible}
      />
    </div>
  );
}

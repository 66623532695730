import { isValid } from 'date-fns';
import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { amountField, sharesField, simpleTextField } from '../../../data-fields/CommonFields';
import { RendererType } from '../../../data-models/field.data-model';
import { ICurrencyMeta } from '../../../data-models/field3.data-model';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';
import { fundsByIdMapState } from '../../../services/state/AppConfigState';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { formatDateNumeric } from '../../../util/formatters/DateFormatters';
import { createFormField, IFormField } from '../../../view-models/form.view-model';
import {
  ITransactionViewModel,
  restructureKeyToFieldKey,
} from '../../../view-models/transaction-form.view-model';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useShareExchangeFields(companyId: number) {
  const metrics = useRecoilValue(companyMetricsByIdState(companyId));
  const fundsMap = useRecoilValue(fundsByIdMapState);
  const transactionDate = useWatch<ITransactionViewModel>({ name: 'transactionDate' });
  const getCommonInitialFields = useFundDateCurrencyFields();

  return useCallback(() => {
    const fields: IFormField<unknown>[] = [
      ...getCommonInitialFields(),
      sharesField({ key: 'receivedSharesNo', label: 'Received Shares', required: true }),
      amountField({ key: 'receivedPricePerShare', label: 'Received PPS', required: true }),
      simpleTextField({ key: 'security', label: 'Received Security' }),
    ];

    const latestRestructureTransactions = getRestructureIdsMeta(
      metrics?.metrics[0]?.transactions ?? [],
      new Date(transactionDate as string)
    );
    const restructureFields = Array.from(latestRestructureTransactions.values()).map((transaction) => {
      const { fundId, position, restructureId, transactionDate } = transaction;
      const label = `Exchanged shares for ${position ?? formatDateNumeric(transactionDate)}`;
      const fundLabel = fundId && fundsMap.get(fundId)?.name ? `Fund: ${fundsMap.get(fundId)?.name}, ` : '';
      const description = `${fundLabel}Acquired Date: ${formatDateNumeric(transactionDate)}`;

      return createFormField<ICurrencyMeta>({
        key: `_viewModel.exchangedSharesByRestructureId.${restructureKeyToFieldKey(restructureId ?? '')}`,
        label,
        description,
        required: true,
        renderer: RendererType.number,
      });
    });

    fields.push(...restructureFields);

    return fields;
  }, [fundsMap, getCommonInitialFields, metrics?.metrics, transactionDate]);
}

type IRestructureMeta = Pick<MetricsTransactionDataModel, 'transactionDate' | 'fundId' | 'position'> & {
  restructureId: string;
};

export function getRestructureIdsMeta(transactions: MetricsTransactionDataModel[], newTransactionDate: Date) {
  const res = new Map<string, IRestructureMeta>();
  const quantitySumsByRestructureId = new Map<string, number>();

  if (transactions.length === 0 || !isValid(newTransactionDate)) {
    return res;
  }

  transactions.forEach((transaction) => {
    const { fundId, position, quantity, restructureId, transactionDate: transactionDateISO } = transaction;
    const transactionDate = new Date(transactionDateISO);
    const isBeforeNewTransactionDate = transactionDate.getTime() < newTransactionDate.getTime();

    if (restructureId && isBeforeNewTransactionDate) {
      const currentLatestTransaction = res.get(restructureId);
      const isLatestTransaction =
        currentLatestTransaction === undefined ||
        (transactionDate.getTime() >= new Date(currentLatestTransaction.transactionDate).getTime() &&
          quantity > 0);

      if (isLatestTransaction) {
        res.set(restructureId, {
          transactionDate: transactionDateISO,
          position: position ?? currentLatestTransaction?.position,
          fundId: fundId ?? currentLatestTransaction?.fundId,
          restructureId,
        });
      }

      quantitySumsByRestructureId.set(
        restructureId,
        (quantitySumsByRestructureId.get(restructureId) ?? 0) + quantity
      );
    }
  });

  for (const meta of res.values()) {
    if (quantitySumsByRestructureId.get(meta.restructureId)! <= 0) {
      res.delete(meta.restructureId);
    }
  }

  return res;
}

import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { getFormatterModelForField3, IField3 } from '../data-models/field3.data-model';
import { IDisplayField } from '../view-models/display-field.view-model';
import {
  FormatterFn,
  FormatterService,
  IPartialFormatterDataModel,
  StandardFormatterId,
} from './formatter-service';

export class AgFormatterService {
  static #instance: AgFormatterService | undefined;
  #formattersMap;

  private constructor() {
    this.#formattersMap = new Map<string, ValueFormatterFunc<unknown, unknown>>();
  }

  getFormatterForFieldV3<TData, TValue>(field: IField3<unknown>): ValueFormatterFunc<TData, TValue> {
    const model = getFormatterModelForField3(field);

    return this.#getOrCreateFormatter(model);
  }

  getFormatterForId<TData, TValue>(formatterId: StandardFormatterId): ValueFormatterFunc<TData, TValue> {
    if (this.#formattersMap.has(formatterId)) {
      return this.#formattersMap.get(formatterId) as ValueFormatterFunc<TData, TValue>;
    }

    return this.#getOrCreateFormatterForId(formatterId) ?? defaultAgFormatter;
  }

  getFormatterForFormField<TData, TValue>(field: IDisplayField<unknown>) {
    const formatter = FormatterService.get().getFormatterForFormField(field);

    return this.#createGridFormatter<TData, TValue>(formatter);
  }

  getFormatterForModel(formatterDataModel: IPartialFormatterDataModel<unknown>) {
    if (formatterDataModel.id) {
      return this.#formattersMap.get(formatterDataModel.id) ?? this.#getOrCreateFormatter(formatterDataModel);
    }

    return (
      this.#formattersMap.get(formatterDataModel.type as string) ??
      this.#getOrCreateFormatter(formatterDataModel)
    );
  }

  setFormatterForId<TData, TValue>(formatterId: string, formatter: ValueFormatterFunc<TData, TValue>): void {
    this.#formattersMap.set(formatterId, formatter as FormatterFn<unknown>);
  }

  #getOrCreateFormatter<TData, TValue>(
    formatterDataModel: IPartialFormatterDataModel<unknown>
  ): ValueFormatterFunc<TData, TValue> {
    const formatterId = formatterDataModel.id;
    if (this.#formattersMap.has(formatterId)) {
      return this.#formattersMap.get(formatterId) as ValueFormatterFunc<TData, TValue>;
    }

    const formatter = FormatterService.get().getFormatterForModel(formatterDataModel);
    const gridFormatter = this.#createGridFormatter<TData, TValue>(formatter);

    this.setFormatterForId(formatterId, gridFormatter);

    return gridFormatter;
  }

  #getOrCreateFormatterForId<TData, TValue>(
    formatterId: StandardFormatterId
  ): ValueFormatterFunc<TData, TValue> {
    if (this.#formattersMap.has(formatterId)) {
      return this.#formattersMap.get(formatterId) as ValueFormatterFunc<TData, TValue>;
    }

    const formatter = FormatterService.get().getFormatterForId(formatterId);
    const fieldPath = formatterId === 'transactionType' ? 'transactionTypeId' : undefined;
    const gridFormatter = this.#createGridFormatter<TData, TValue>(formatter, fieldPath);

    this.setFormatterForId(formatterId, gridFormatter);

    return gridFormatter;
  }

  #createGridFormatter<TData, TValue>(
    formatter: FormatterFn<TValue>,
    fieldPath?: string
  ): ValueFormatterFunc<TData, TValue> {
    return (params) => {
      const value = fieldPath ? get(params.data, fieldPath) : params.value;
      if (value === undefined || value === null) {
        return '';
      }
      return formatter(value);
    };
  }

  static initService() {
    if (!this.#instance) {
      this.#instance = new AgFormatterService();
    }

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      this.#instance = this.initService();
    }
    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

export function defaultAgFormatter<TData, TValue>(params: ValueFormatterParams<TData, TValue>) {
  return params.value;
}

import { useEffect, useState } from 'react';

export function useLoadData<T>({ query, forceReload }: { query: () => Promise<T>; forceReload?: unknown }) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let cancel = false;
    async function fetchData() {
      setLoading(true);
      try {
        const data = await query();
        if (!cancel) setData(data);
      } catch (err) {
        console.error(err);
        if (!cancel) setError(true);
      } finally {
        if (!cancel) setLoading(false);
      }
    }
    fetchData();
    return () => {
      cancel = true;
    };
  }, [query, forceReload]);

  return { data, loading, error };
}

export enum FeatureFlags {
  competitiveIntelligenceModule = 'competitiveIntelligenceModule',
  enableEditCommentsFromCard = 'enableEditCommentsFromCard',
  enableResponseAutosave = 'enableResponseAutosave',
  showAcquiredCompaniesTooltip = 'showAcquiredCompaniesTooltip',
  showAcquirerData = 'showAcquirerData',
  showActivityDrawer = 'showActivityDrawer',
  showAllSecurities = 'showAllSecurities',
  showAllTransactions = 'showAllTransactions',
  showBulkUploadStatusUpdates = 'showBulkUploadStatusUpdates',
  showCEOPanel = 'showCEOPanel',
  showCapTable = 'showCapTable',
  showCommentary = 'showCommentary',
  showConfigKPIForm2 = 'showConfigKPIForm2',
  showCreateRequest = 'showCreateRequest',
  showCustomFieldForm2 = 'showCustomFieldForm2',
  showDataCollectionCompanies2 = 'showDataCollectionCompanies2',
  showDataCollectionModule = 'showDataCollectionModule',
  showDataDisplayConfig = 'showDataDisplayConfig',
  showDownloadInLists = 'showDownloadInLists',
  showDropBoxEmbedder = 'showDropBoxEmbedder',
  showElasticSearchResults = 'showElasticSearchResults',
  showExpandedPerfCharts = 'showExpandedPerfCharts',
  showFinance2 = 'showFinance2',
  showFinanceSecurity = 'showFinanceSecurity',
  showFinancialsDatePicker = 'showFinancialsDatePicker',
  showFinancialsMenu = 'showFinancialsMenu',
  showFinancialsPinnedYears = 'showFinancialsPinnedYears',
  showFoundersList = 'showFoundersList',
  showFundProfiles = 'showFundProfiles',
  showFundReporting = 'showFundReporting',
  showFundReserves = 'showFundReserves',
  showFundsKPI = 'showFundsKPI',
  showGeneralLedger = 'showGeneralLedger',
  showGranularitySelector = 'showGranularitySelector',
  showIntegrations = 'showIntegrations',
  showKpiCurrencySelector = 'showKpiCurrencySelector',
  showKpiResponsesInDrawer = 'showKpiResponsesInDrawer',
  showManageGroups = 'showManageGroups',
  showMappingSection = 'showMappingSection',
  showMetricsRecon = 'showMetricsRecon',
  showNotesInActivityDrawer = 'showNotesInActivityDrawer',
  showNotifications = 'showNotifications',
  showPartialExit = 'showPartialExit',
  showPeopleReporting = 'showPeopleReporting',
  showReconciliation = 'showReconciliation',
  showRoundDrillDown = 'showRoundDrillDown',
  showRoundSource = 'showRoundSource',
  showRubricConfig = 'showRubricConfig',
  showScenarioModelling = 'showScenarioModelling',
  showSecondaryValueInCard = 'showSecondaryValueInCard',
  showSecurityView = 'showSecurityView',
  showTransactionSummary2 = 'showTransactionSummary2',
  showTransactionTypeSelector2 = 'showTransactionTypeSelector2',
  showTransactionV3AsPrimary = 'showTransactionV3AsPrimary',
  showWaterfallFormulas = 'showWaterfallFormulas',
}

export function getFeatureFlagsDefaults() {
  return Object.keys(FeatureFlags).reduce(
    (res, key) => {
      res[key as FeatureFlags] = false;

      return res;
    },
    {} as Record<FeatureFlags, boolean>
  );
}

export type MaggieFeatureFlags = ReturnType<typeof getFeatureFlagsDefaults>;

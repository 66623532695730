import { useRef, useEffect, useCallback, PropsWithChildren } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { GetRowIdParams } from 'ag-grid-community';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { kpiTemplateAssignmentsListState } from '../../../../services/state/KPI/KPICompaniesState';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { useAssignedFilter } from './hooks/useAssignedFilter';
import { useAllKpiCompaniesActions } from './AllCompaniesForms/useAllKpiCompaniesActions';
import { allCompaniesActionState } from './AllCompaniesState';
import { IAssignedRowData, useAssignedCompaniesData } from './AssignedCompaniesData';
import { defaultColDef, useAssignedCompaniesColDefs } from './AssignedCompaniesColDefs';
import { AssignModal } from './AllCompaniesForms/AssignModal';
import { DeleteAssignmentModal } from './AllCompaniesForms/DeleteAssignmentModal';
import { CreateRequestModal } from './AllCompaniesForms/CreateRequestModal';

function AssignedCompaniesModals() {
  const actionState = useRecoilValue(allCompaniesActionState);
  if (!actionState) return null;
  return (
    <>
      {actionState === 'createRequest' && <CreateRequestModal />}
      {actionState === 'deleteAssignment' && <DeleteAssignmentModal />}
      {actionState === 'editAssignment' && <AssignModal />}
    </>
  );
}

function AssignedCompaniesContent() {
  return (
    <>
      <AssignedCompaniesModals />
      <AssignedCompaniesGrid />
    </>
  );
}

// prevents rerendering of the grid when the modals are opened
function ResetStateWrapper({ children }: PropsWithChildren) {
  const { resetUIState } = useAllKpiCompaniesActions();

  useEffect(() => {
    return resetUIState;
  }, [resetUIState]);

  return <>{children}</>;
}

export function AssignedCompanies() {
  const templates = useRecoilValue(kpiTemplateAssignmentsListState);

  if (!templates) {
    return null;
  }

  return (
    <ResetStateWrapper>
      <AssignedCompaniesContent />
    </ResetStateWrapper>
  );
}

export function AssignedCompaniesGrid() {
  const colDefs = useAssignedCompaniesColDefs();
  const rowData = useAssignedCompaniesData();

  const gridRef = useRef<AgGridReact>(null);
  const { onGridReady } = useAssignedFilter();

  const getRowId = useCallback(
    (params: GetRowIdParams<IAssignedRowData>) =>
      !params.data ? '' : `${params.data.id}-${params.data.company?.id}`,
    []
  );

  return (
    <Stack height={'100%'}>
      <div style={{ height: '100%', paddingTop: '2rem' }}>
        <FadeInGridWrapper>
          <AgTable
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            groupIncludeTotalFooter={false}
            rowGroupPanelShow='always'
            groupDefaultExpanded={-1}
            suppressMovableColumns={true}
            suppressRowDrag={true}
            onGridReady={onGridReady}
            suppressRowGroupHidesColumns={false}
            getRowId={getRowId}
          />
        </FadeInGridWrapper>
      </div>
    </Stack>
  );
}

import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import {
  selectedDateFPState,
  selectedFundIdStateFP,
  selectedFundReservesState,
  selectedFundStateFP,
} from '../state/FPState';
import { usePortCos } from '../../Finance2/hooks/usePortCos';
import { FundReservesSummary } from './FundReservesSummary';
import { FundReservesGrid } from './FundReservesGrid';

export function FundReserves() {
  const { dataReady } = usePortCos();
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const fund = useRecoilValue(selectedFundStateFP);
  const selectedDate = useRecoilValue(selectedDateFPState);
  const reserves = useRecoilValue(selectedFundReservesState({ fundId, date: selectedDate }));
  if (!reserves || !fund || !dataReady) return null;
  return (
    <Box pt='0.02rem'>
      <FundReservesSummary reserves={reserves} />
      <FundReservesGrid fund={fund} reserves={reserves} hypothetical={true} />
      <FundReservesGrid fund={fund} reserves={reserves} hypothetical={false} />
    </Box>
  );
}

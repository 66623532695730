import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clone } from 'lodash-es';
import { compareAsc, differenceInMonths, differenceInQuarters, differenceInYears } from 'date-fns';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ConfirmDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDialog';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { getEachDateByPeriodAndFrequency } from '../../../utils/financialUtils';
import { allCompanyFinancialsState } from '../../../state/CompanyFinancialsState';
import {
  selectedEndDateFinancialsState,
  selectedFrequencyFinancialsState,
} from '../../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { companyState } from '../../../../../services/state/CompanyState';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { useFinancialsExportColumnDefs } from '../export/useFinancialsExportColumnDefs';
import { useFinancialsExportData } from '../export/useFinancialsExportData';
import { SelectPeriodDropdown } from './SelectPeriodDropdown/SelectPeriodDropdown';
import { useSelectPeriodDropdown } from './SelectPeriodDropdown/useSelectPeriodDropdown';
import { ToggleFrequencyButtons } from './ToggleFrequencyButtons';
import { useFinancialsSortState } from './financialsSortState';

type CompanyProfileFinancialsExportProps = {
  open: boolean;
  onClose: () => void;
};

export function CompanyProfileFinancialsExportModal({ open, onClose }: CompanyProfileFinancialsExportProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId))!;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [frequency, setFrequency] = useRecoilState(selectedFrequencyFinancialsState(companyId));
  const [checkedByPeriod, setCheckedByPeriod] = useState(true);
  const financials = useRecoilValue(allCompanyFinancialsState(companyId));
  const [checkedByAllHistorical, setCheckedByAllHistorical] = useState(false);
  const { totalSelectedPeriods, selectedAmountOfPeriodsIdx, onChangeSelectedAmountOfPeriods } =
    useSelectPeriodDropdown(frequency);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const oldestCompanyFinancialsDate = useMemo(() => {
    if (!financials?.length) return new Date();
    const sortedFinancials = clone(financials).sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)));

    return new Date(sortedFinancials[0].date);
  }, [financials]);

  let nPeriods = totalSelectedPeriods;
  if (checkedByAllHistorical) {
    switch (frequency) {
      case KpiPeriod.month: {
        nPeriods = differenceInMonths(new Date(), oldestCompanyFinancialsDate) + 1;
        break;
      }
      case KpiPeriod.quarter: {
        nPeriods = differenceInQuarters(new Date(), oldestCompanyFinancialsDate) + 1;
        break;
      }
      case KpiPeriod.year: {
        nPeriods = differenceInYears(new Date(), oldestCompanyFinancialsDate) + 1;
        break;
      }
    }
  }
  const selectedEndDate = useRecoilValue(selectedEndDateFinancialsState(companyId));

  const allPeriods = useMemo(
    () =>
      getEachDateByPeriodAndFrequency({
        totalSelectedPeriods: nPeriods,
        frequency,
        fye,
        endDate: selectedEndDate,
      }),
    [nPeriods, frequency, fye, selectedEndDate]
  );

  const getRowData = useFinancialsExportData();
  const rowData = useMemo(() => getRowData(frequency), [frequency, getRowData]);

  const { sortState } = useFinancialsSortState();
  const { columnDefs, defaultExportParams } = useFinancialsExportColumnDefs({
    allPeriods: allPeriods.dates,
    frequency,
    company,
    sortState,
  });

  const onChangeSelectedPeriod = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedByAllHistorical(false);
    setCheckedByPeriod(event.target.checked);
  }, []);

  const onChangeSelectedAllHistorical = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedByPeriod(false);
    setCheckedByAllHistorical(event.target.checked);
  }, []);

  const onConfirmExport = useCallback(() => {
    if (!gridApi) return;
    gridApi.exportDataAsExcel();
    onClose();
  }, [gridApi, onClose]);

  const isACheckboxChecked = !checkedByPeriod && !checkedByAllHistorical;

  const onGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  return (
    <GenericFallbacksWrapper>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        aria-labelledby='company-financials-export-dialog-title'
        title='Export Historical Financials'
        onConfirm={onConfirmExport}
        footerButtonsProps={{
          confirmButtonText: 'Export to Excel',
          onConfirmDisabled: isACheckboxChecked,
        }}
      >
        <Stack direction='column' gap='0.25rem' justifyContent={'center'}>
          <Typography>Period</Typography>
          <ToggleFrequencyButtons frequency={frequency} setFrequency={setFrequency} />
        </Stack>

        <Divider
          sx={{
            my: '1.75rem',
          }}
        />
        <Stack gap='1rem'>
          <Stack direction='row' gap='0.5rem'>
            <Checkbox checked={checkedByPeriod} onChange={onChangeSelectedPeriod} />
            <SelectPeriodDropdown
              period={frequency}
              onChange={onChangeSelectedAmountOfPeriods}
              selectedAmountOfPeriodsIdx={selectedAmountOfPeriodsIdx}
            />
          </Stack>
          <Stack direction='row' gap='0.5rem' alignItems={'center'}>
            <Checkbox checked={checkedByAllHistorical} onChange={onChangeSelectedAllHistorical} />
            <Typography>All historical data</Typography>
          </Stack>
        </Stack>
      </ConfirmDialog>
      <div
        style={{
          // Basically we hide this table to make it only available for export
          display: 'none',
        }}
      >
        <AgTable
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          groupIncludeTotalFooter={false}
          rowGroupPanelShow='always'
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          defaultExcelExportParams={defaultExportParams}
          defaultCsvExportParams={defaultExportParams}
        ></AgTable>
      </div>
    </GenericFallbacksWrapper>
  );
}

import { atom, useAtomValue, useSetAtom } from 'jotai';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { GeneralLedgerUploadData } from '../../../schemas/GeneralLedgerUploadData.schema';
import { EmptySection } from '../../../components/EmptySection';
import { useLoadData } from '../../../services/queries-hooks/useLoadData';
import { getGeneralLedgerUploadData } from '../../../services/queries/MaggieTransactionQueries';
import { Loader } from '../../../components/Loader/Loader';
import { UploadLedgerModal } from './UploadLedgerModal';
import { selectedGeneralLedgerActionState } from './generalLedgerState';
import { GeneralLedgerUploadsGrid } from './GeneralLedgerUploadsGrid';

export const invalidateGeneralLedgerData = atom(0);

export function GeneralLedgerLoader() {
  const invalidate = useAtomValue(invalidateGeneralLedgerData);
  const { data, loading, error } = useLoadData({
    query: getGeneralLedgerUploadData,
    forceReload: invalidate,
  });

  if (!data) {
    return (
      <Box width='100%' height='100%' m='1rem'>
        {loading ? <Loader /> : error ? <Alert severity='error'>Failed to load data</Alert> : null}
      </Box>
    );
  }
  return (
    <Box width='100%'>
      <UploadLedgerModal />
      <GeneralLedger data={data as GeneralLedgerUploadData[]} />
    </Box>
  );
}

interface IGeneralLedgerProps {
  data: GeneralLedgerUploadData[];
}
export function GeneralLedger({ data }: IGeneralLedgerProps) {
  const hasData = data.length > 0;
  return (
    <Typography color='text.primary' component='section' width={'100%'} p={'1rem 2rem'}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={'1rem'}>
        <Typography variant={'h3'}>General Ledger</Typography>
        {hasData && <UploadLedgerButton />}
      </Stack>
      <Stack>
        {!hasData ? (
          <EmptySection
            title='No General Ledger'
            message='Please upload general ledger from Carta'
            actionComponent={<UploadLedgerButton />}
          />
        ) : (
          <GeneralLedgerUploadsGrid data={data} />
        )}
      </Stack>
    </Typography>
  );
}

function UploadLedgerButton() {
  const setSelectedAction = useSetAtom(selectedGeneralLedgerActionState);
  return (
    <Button
      size='medium'
      variant='contained'
      color='secondary'
      startIcon={<PublishIcon />}
      onClick={() => setSelectedAction('upload')}
    >
      Upload General Ledger
    </Button>
  );
}

import { styled } from '@mui/material/styles';
import {
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  GridOptions,
  GridReadyEvent,
  InitialGroupOrderComparatorParams,
  KeyCreatorParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ForwardedRef, forwardRef, memo, useCallback, useMemo } from 'react';
import { agForesightTheme } from '../../theme/ag-foresight';
import { genericComparator } from '../../util/comparators';
import { customColumnTypes } from './customColumnTypes';
import { customTableNotation } from './customTableNotation';
import { commonProcessCb, getExportParams, useExportToExcelStyles } from './exportToExcelDefs';
import { UNKNOWN_GROUP_NAME } from './tableConstants';

const AgTableForwarded = forwardRef(function AgTable(
  props: AgGridReactProps,
  ref: ForwardedRef<AgGridReact<unknown>>
) {
  const initialGroupOrderComparator = useCallback(
    (params: InitialGroupOrderComparatorParams) => {
      if (typeof props?.initialGroupOrderComparator === 'function') {
        return props?.initialGroupOrderComparator(params);
      }

      const a = params.nodeA.key || '';
      const b = params.nodeB.key || '';
      return a.localeCompare(b, 'en', { sensitivity: 'base' });
    },
    [props]
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      width: 120,
      suppressSizeToFit: true,
      resizable: true,
      sortable: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'] as const,
      filter: true,
      wrapHeaderText: false,
      autoHeaderHeight: true,
      keyCreator: (params: KeyCreatorParams) => params.value ?? UNKNOWN_GROUP_NAME,
      ...(props?.defaultColDef ?? {}),
      filterParams: {
        buttons: ['reset'],
        newRowsAction: 'keep',
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value;
        },
        ...props?.defaultColDef?.filterParams,
      },
    }),
    [props?.defaultColDef]
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      props.gridOptions?.onGridReady?.(params);
    },
    [props.gridOptions]
  );

  const excelStyles = useExportToExcelStyles();

  const gridOptions: GridOptions = useMemo(
    () => ({
      ...customTableNotation,
      columnTypes: { ...customColumnTypes },
      excelStyles: excelStyles,
      ...(props?.gridOptions ?? {}),
      onGridReady,
    }),
    [excelStyles, onGridReady, props?.gridOptions]
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      width: 200,
      sortable: true,
      pinned: 'left',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      comparator: genericComparator,
      ...(props?.autoGroupColumnDef ?? {}),
    }),
    [props?.autoGroupColumnDef]
  );

  const defaultExcelExportParams = useMemo(() => {
    const customParams = getExportParams(props.defaultExcelExportParams as ExcelExportParams);
    return {
      processCellCallback: commonProcessCb,
      ...customParams,
    } as ExcelExportParams;
  }, [props.defaultExcelExportParams]);

  const defaultCsvExportParams = useMemo(() => {
    const customParams = getExportParams(props.defaultCsvExportParams);
    return {
      processCellCallback: commonProcessCb,
      ...customParams,
    } as CsvExportParams;
  }, [props.defaultCsvExportParams]);

  return (
    <StyledTableWrapper data-testid='ag-table'>
      <AgGridReact
        animateRows
        data-data-ref={ref}
        groupIncludeTotalFooter
        rowGroupPanelShow={'always'}
        suppressDragLeaveHidesColumns
        theme={props.theme ?? agForesightTheme}
        {...props}
        className='ag-theme-1 ag-theme-foresight'
        autoGroupColumnDef={autoGroupColumnDef}
        defaultColDef={defaultColDef}
        defaultCsvExportParams={defaultCsvExportParams}
        defaultExcelExportParams={defaultExcelExportParams}
        gridOptions={gridOptions}
        initialGroupOrderComparator={initialGroupOrderComparator}
      />
    </StyledTableWrapper>
  );
});

const StyledTableWrapper = styled('div')`
  margin: 0;
  height: 100%;
  width: 100%;

  .ag-root-wrapper {
    background: transparent;
    .ag-row.ag-row-footer {
      background-color: ${({ theme }) => theme.colors.secondary[5]};
      box-shadow: ${({ theme }) => `0 -0.1rem ${theme.colors.neutral[20]}`};
    }
  }
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-height: 100%;

  .ag-theme-foresight {
    .ag-row-group-contracted {
      background-color: #f8f8ff;
    }
    .ag-row-group.ag-row-group-expanded[class*='ag-row-level-'] {
      background-color: #f5f7ff;
      /* background-color: #f5f7ff; */
    }
    .ag-row-group.ag-row-group-expanded.ag-row-level-0 {
      background-color: #e7e8ff;
    }

    .ag-row-group:has(img, svg, .MuiAvatar-root) {
      align-items: center;
    }
  }
`;

export const AgTable = memo(AgTableForwarded);

import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Fund } from '../../../schemas/Fund.schema';
import { FundReserves } from '../../../schemas/FundReserves.schema';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { AgTable } from '../../../components/AgTable/AgTable';
import {
  defaultReservesColDefs,
  useDefaultReservesColGroupDef,
  useReservesColDefs,
} from './reservesGridColDefs';
import { getReservesData, reservesAggFuncs, reservesRowClassRules } from './reservesGridData';

const ReservesTableContainer = css`
  margin-bottom: 1rem;
  height: 40vh;
  & .currentInvestment {
    opacity: 0.7;
  }
`;

interface IFundReservesGridProps {
  fund: Fund;
  reserves: FundReserves;
  hypothetical: boolean;
}
export function FundReservesGrid({ fund, reserves, hypothetical }: IFundReservesGridProps) {
  const colDefs = useReservesColDefs(hypothetical);
  const defaultReservesColGroupDef = useDefaultReservesColGroupDef(hypothetical);
  const rowData = useMemo(() => {
    const allData = getReservesData(reserves);
    return hypothetical ? allData.hypotheticalData : allData.portcoData;
  }, [hypothetical, reserves]);

  const context = useMemo(() => {
    return { fund };
  }, [fund]);

  return (
    <>
      <Typography mb='0.5rem'>{hypothetical ? 'Future Investments' : 'Current Investments'}</Typography>
      <div css={ReservesTableContainer}>
        <FadeInGridWrapper>
          <AgTable
            columnDefs={colDefs}
            rowData={rowData}
            suppressRowGroupHidesColumns={true}
            grandTotalRow='top'
            context={context}
            suppressAggFuncInHeader
            defaultColDef={defaultReservesColDefs}
            aggFuncs={reservesAggFuncs}
            rowClassRules={reservesRowClassRules}
            rowGroupPanelShow='never'
            autoGroupColumnDef={defaultReservesColGroupDef}
          />
        </FadeInGridWrapper>
      </div>
    </>
  );
}
